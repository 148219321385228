<div class="scrollToBottom" id="scrollToBottom">
    <div class="mesasge-parent">
        <ng-container  *ngFor="let message of messages">
            <div class="message-row"
                    [ngClass]="{ 'from': message.sentBy === sentbyBot,
                                'to':   message.sentBy === sentbyUser}">
                <div class="message" *ngIf="message?.content !== ''">
                    <span>{{message.content}}</span>
                </div>
                <div class="timeStamp" *ngIf="message?.content !== ''"></div>
            </div>
        </ng-container>
        <ng-container>
            <div class="message-row from" *ngIf="balanceMovement?.chartApperance[0].dataSource?.length">
                <serrala-stacked-chart #serralaStackedChart
                [stackedChart]="balanceMovement"
                (axisLabelRenderEvent)='formatChartBalanceValues($event)' >
                </serrala-stacked-chart>
            </div>
        </ng-container>
        <ng-container>
            <div class="message-row from" *ngIf="showChartDemonstration">
                <serra-chart-container [chartData]="chartData"
                                       [intentName]="intentName">
                </serra-chart-container>
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="scriptedFlow">
        <app-scripted-flow [botResponse]="botResponse" [scriptedResponse]="scriptedResponse"></app-scripted-flow>
    </ng-container>
</div>
