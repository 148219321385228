import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { PipesModule } from '@alevate/serrala-common/pipes';
import { GlobalStorageService } from './core/services/global-storage.services';
import { SettingsService } from './core/services/settings.service';
import { LayoutModule } from './layout/layout.module';
import { DependencyInjector } from './core/services/dependency-injector.service';
import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injector, ErrorHandler, Injectable } from '@angular/core';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
  HttpBackend,
} from '@angular/common/http';

import { OAuthModule } from 'angular-oauth2-oidc';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthLibModule } from '@alevate/serrala-auth-lib';
import { CoreModule } from './core/core.module';
import {
  BaseService,
  ToastService,
  LoaderService,
  LanguageSyncService,
} from '@alevate/serrala-common/services';
import { ErrorInterceptor } from './core/services/error-interceptor.service';
import { ErrorService } from './core/services/error.service';
import { GlobalErrorHandler } from './core/services/global-error-handler';
import { AlevateNavigationRoutingModule } from './alevate-navigation-routing.module';
import { AlevateNavigationComponent } from './alevate-navigation.component';
import { assetUrl } from '../single-spa/asset-url';
import { SessionStorageBrdCrumbData } from '@alevate/serrala-breadcrumb';
import { L10n } from '@syncfusion/ej2-base';
import { forkJoin } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class HttpClientTrans extends HttpClient {
  constructor(handler: HttpBackend) {
    super(handler);
  }
}

export function createTranslateLoader(http: HttpClientTrans) {
  return new TranslateHttpLoader(http, assetUrl('/i18n/'), '.json');
}

@NgModule({
  declarations: [AlevateNavigationComponent, EmptyRouteComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClientTrans],
      },
    }),
    AuthLibModule.forRoot(false),
    AlevateNavigationRoutingModule,
    PipesModule,
    LayoutModule,
    CoreModule,
  ],
  providers: [
    BaseService,
    ToastService,
    SettingsService,
    GlobalStorageService,
    LoaderService,
    SessionStorageBrdCrumbData,
    { provide: APP_BASE_HREF, useValue: environment.baseHref },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ErrorService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AlevateNavigationComponent],
})
export class AlevateNavigationModule {
  constructor(
    injector: Injector,
    http: HttpClientTrans,
    translation: TranslateService,
    langSync: LanguageSyncService
  ) {
    DependencyInjector.setInjector(injector);

    langSync.registerService(translation);

    const syncfusionTranslateLoader = new TranslateHttpLoader(
      http,
      assetUrl('/i18n/syncfusion/'),
      '.json'
    );
    forkJoin([
      syncfusionTranslateLoader.getTranslation('de'),
      syncfusionTranslateLoader.getTranslation('en-US'),
    ]).subscribe({
      next: ([deJson, enJson]: [any, any]) =>
        L10n.load({
          de:{ 
            ...deJson['de'],
            grid: {
              ...deJson['de'].grid,
              ChooseColumns: 'Spaltenauswahl',
              Search: 'Suchen',
              SelectAll: 'Alle auswählen',
              OKButton: 'Ok',
              CancelButton: 'Abbruch',
            }
          },
          en: enJson['en-US'],
        }),
    });

    // BaseService.setBaseInjector(injector);
    // console.log(
    //   'NAVIAGTION MODULE Injector baseInjector: ',
    //   BaseService.baseInjector
    // );
    // console.log(
    //   'NAVIAGTION MODULE Injector getBaseInjector: ',
    //   BaseService.getBaseInjector()
    // );
    // BaseService.inj = injector;
    // console.log('NAVIAGTION MODULE Inj : ', BaseService.inj);
  }
}
