<div *ngIf="tokenRefreshFailed" class="session-expired">
    <div class="main-container">
        <div class="session-expired-container">
            <div class="logo-container">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="120" height="59" viewBox="0 0 120 59" class="logo">
                    <title>Serrala Logo</title>
                    <defs>
                        <path id="a" d="M69.118 19.612l17.998-14.85 26.959 22.57H78.443l-9.325-7.72zm-14.44 7.18l-8.784-7.128 8.785-7.24 8.692 7.184-8.692 7.185zm-23.868.54H9.576l26.382-10.928 4.15 3.278-9.298 7.65zm88.967 0l.003-.002L87.116 0l-20.87 17.234L54.68 7.675l-11.686 9.633-6.297-5.116L0 27.332v3.655h32.132l10.861-8.949 11.686 9.495 11.563-9.547 10.871 9h42.667v-3.654h-.004zm-8.728 26.055h3.033l-1.507-5.284-1.526 5.284zm-.772-8.472h5.118l4.385 13.735h-4.192l-.734-2.554h-4.577l-.734 2.554h-3.689l4.423-13.735zm-18.719 0h3.98v10.68h5.214v3.055h-9.194V44.915zm-14.722 8.472h3.032l-1.507-5.284-1.525 5.284zm-.773-8.472h5.118l4.384 13.735h-4.19l-.735-2.554h-4.577l-.734 2.554h-3.69l4.424-13.735zm-16.17 6.262c1.334 0 1.99-.557 1.99-1.67v-.078c0-1.114-.694-1.556-1.97-1.556H58.62v3.304h1.274zm-5.213-6.262h5.408c3.611 0 5.62 1.46 5.62 4.36v.078c0 1.959-1.042 3.054-2.492 3.61l3.303 5.687h-4.171l-2.801-4.918h-.927v4.918h-3.94V44.915zm-13.231 6.262c1.333 0 1.99-.557 1.99-1.67v-.078c0-1.114-.697-1.556-1.97-1.556h-1.295v3.304h1.275zm-5.215-6.262h5.408c3.612 0 5.62 1.46 5.62 4.36v.078c0 1.959-1.042 3.054-2.491 3.61l3.303 5.687H43.9l-2.8-4.918h-.927v4.918h-3.94V44.915zm-17.612 0h9.541v3.035h-5.601v2.363h4.461v2.805h-4.461v2.497h5.91v3.035h-9.85V44.915zM0 54.29h3.766c.097.98.54 1.786 2.183 1.786 1.14 0 1.777-.557 1.777-1.364 0-.845-.521-1.076-2.183-1.325-3.901-.462-5.215-1.614-5.215-4.38 0-2.479 2.047-4.285 5.35-4.285 3.322 0 5.196 1.383 5.447 4.284h-3.67c-.135-.98-.695-1.478-1.777-1.478-1.062 0-1.603.48-1.603 1.17 0 .77.425 1.115 2.144 1.346 3.496.384 5.293 1.287 5.293 4.265 0 2.516-2.009 4.572-5.563 4.572C2.086 58.88.116 57.267 0 54.29z"/>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <mask id="b" fill="#fff">
                            <use xlink:href="#a"/>
                        </mask>
                        <use fill="#EE1F60" xlink:href="#a"/>
                        <g fill="#FFF" mask="url(#b)">
                            <path d="M0 0h120v59H0z"/>
                        </g>
                    </g>
                </svg>
            </div>
            <div class="form-container">
                <div class="form-wrapper">
                    <div class="row">
                        <div class="col-lg-12">
                            <h5 class="text-bold">
                                {{'SESSION_EXPIRED.content.title' | translate}}
                            </h5>
                            <div class="row mt-8">
                                <div class="col-12">
                                    <button (click)="logoutUser()" class="e-control e-btn e-lib e-primary mt-4">
                                        {{'SESSION_EXPIRED.BUTTONS.LOGIN' | translate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>