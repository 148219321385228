/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { enableRipple } from '@syncfusion/ej2-base';
import { GLOBAL_NAVBAR, POWER_PANEL } from './layout.config';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AuthenticationService } from '@alevate/serrala-auth-lib';
import { EVENT_TYPES } from '@alevate/serrala-common/constants';
import { Constants } from '@app/core/constants';

enableRipple(true);

import { AutoUnsubscribe } from '@alevate/serrala-common/decorators';

@AutoUnsubscribe
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  showSidebar = false;
  breadCrumbDetails;
  isGlobalNavbarPinned = false;
  isPowerPanelPinned = false;
  mainSectionContainerId = 'sectionContainer';
  hideBreadcrumb;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.addEventListener('breadCrumb', (e: any) => {
      this.breadCrumbDetails = JSON.parse(sessionStorage.getItem('breadCrumb'));
    });

    document.addEventListener(EVENT_TYPES.hideLayout, (e: any) => {
      this.showSidebar = false;
    });

    document.addEventListener(EVENT_TYPES.toggleBreadcrumb, (e: any) => {
      this.hideBreadcrumb = e.detail?.isVisible ? false : true;
    });

    if (this.router?.events) {
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe({
          next: () => {
            if (
              !this.authenticationService.isLoggedIn() ||
              this.router.url.includes('registrationConfirm') ||
              this.router.url.includes('invitationConfirm')
            ) {
              this.showSidebar = false;
            } else {
              this.showSidebar = true;
            }
          },
        });
    }
  }

  /**
   *After View Checked
   *
   * @memberof AddEditBtfMappingComponent
   */
  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }
  /**
   *
   * Section container width is adjusting based on
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const clientWidth = document.body.clientWidth;
    const currentGlobalNavbarWidth = this.isGlobalNavbarPinned
      ? GLOBAL_NAVBAR.expadedWidth
      : GLOBAL_NAVBAR.collapsedWidth;
    const currentPowerPanelWidth = this.isPowerPanelPinned
      ? POWER_PANEL.expadedWidth
      : POWER_PANEL.collapsedWidth;
    document.getElementById(this.mainSectionContainerId).style.width =
      clientWidth - currentGlobalNavbarWidth - currentPowerPanelWidth + 'px';
  }

  toggledPowerPanel(isPowerpanelPinned) {
    const clientWidth = document.body.clientWidth;
    const currentGlobalNavbarWidth = this.isGlobalNavbarPinned
      ? GLOBAL_NAVBAR.expadedWidth
      : GLOBAL_NAVBAR.collapsedWidth;
    if (isPowerpanelPinned) {
      document
        .getElementById(this.mainSectionContainerId)
        .classList.add('pinned-power-panel');
      this.isPowerPanelPinned = true;
      document.getElementById(this.mainSectionContainerId).style.width =
        clientWidth -
        POWER_PANEL.expadedWidth -
        currentGlobalNavbarWidth +
        'px';
      document.getElementById(this.mainSectionContainerId).style.marginRight =
        POWER_PANEL.expadedWidth + 'px';
    } else {
      document
        .getElementById(this.mainSectionContainerId)
        .classList.remove('pinned-power-panel');
      this.isPowerPanelPinned = false;
      document.getElementById(this.mainSectionContainerId).style.width =
        clientWidth -
        POWER_PANEL.collapsedWidth -
        currentGlobalNavbarWidth +
        'px';
      document.getElementById(this.mainSectionContainerId).style.marginRight =
        POWER_PANEL.collapsedWidth + 'px';
    }
  }

  toggledSidebar(isSidebarPinned) {
    const clientWidth = document.body.clientWidth;
    const currentPowerPanelWidth = this.isPowerPanelPinned
      ? POWER_PANEL.expadedWidth
      : POWER_PANEL.collapsedWidth;
    if (isSidebarPinned) {
      document
        .getElementById(this.mainSectionContainerId)
        .classList.add('pinned');
      this.isGlobalNavbarPinned = true;
      document.getElementById(this.mainSectionContainerId).style.width =
        clientWidth -
        GLOBAL_NAVBAR.expadedWidth -
        currentPowerPanelWidth +
        'px';
      document.getElementById(this.mainSectionContainerId).style.marginLeft =
        GLOBAL_NAVBAR.expadedWidth + 'px';
    } else {
      document
        .getElementById(this.mainSectionContainerId)
        .classList.remove('pinned');
      this.isGlobalNavbarPinned = false;
      document.getElementById(this.mainSectionContainerId).style.width =
        clientWidth -
        GLOBAL_NAVBAR.collapsedWidth -
        currentPowerPanelWidth +
        'px';
      document.getElementById(this.mainSectionContainerId).style.marginLeft =
        GLOBAL_NAVBAR.collapsedWidth + 'px';
    }
  }

  /**
   * Checks whether the current tenant is in preparation mode.
   *
   * @returns {boolean} `true` if the tenant is in preparation mode, otherwise `false`.
   */
  isTenantInPreparation(): boolean {
    const item = sessionStorage.getItem(
      Constants.TENANT_PREPARATION.SESSION_KEY
    );
    return (
      item !== null && item === Constants.TENANT_PREPARATION.STATUS_PREPARATION
    );
  }
}
