import { Component, Input } from '@angular/core';
import { WIDGETS } from '../../menu';

import { AutoUnsubscribe } from '@alevate/serrala-common/decorators';

@AutoUnsubscribe
@Component({
  selector: 'widget-search',
  templateUrl: './search.component.html',
})
export class SearchComponent {
  @Input() widgetType: WIDGETS;
  WIDGETS = WIDGETS;
}
