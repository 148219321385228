import { Component, OnDestroy } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SyncfusionLicense } from '@alevate/serrala-common';
import { loadCldr, setCulture } from '@syncfusion/ej2-base';
import * as numbers from 'cldr-data/main/de/numbers.json';
import * as caGregorian from 'cldr-data/main/de/ca-gregorian.json';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json';
import * as weekdata from 'cldr-data/supplemental/weekData.json';

loadCldr(numbers, caGregorian, numberingSystems, timeZoneNames, weekdata);

import { AutoUnsubscribe } from '@alevate/serrala-common/decorators';

@AutoUnsubscribe
@Component({
  selector: 'alevate-navigation',
  templateUrl: './alevate-navigation.component.html',
  styleUrls: [],
})
export class AlevateNavigationComponent implements OnDestroy{
  title = 'alevate-navigation';
  constructor(public translateService: TranslateService) {
    translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        if (event.lang === 'en') {
          setCulture('en-US');
          sessionStorage.removeItem('DATE_FORMAT');
          sessionStorage.removeItem('DATETIME_FORMAT');
          sessionStorage.setItem('DATE_FORMAT', 'MM/dd/yyyy');
          sessionStorage.setItem('DATETIME_FORMAT', 'MM/dd/yyyy hh:mm:ss a');
        } else {
          setCulture(event.lang);
          sessionStorage.removeItem('DATE_FORMAT');
          sessionStorage.removeItem('DATETIME_FORMAT');
          sessionStorage.setItem('DATE_FORMAT', 'dd.MM.yyyy');
          sessionStorage.setItem('DATETIME_FORMAT', 'dd.MM.yyyy HH:mm:ss');
        }
      },
    });

    SyncfusionLicense.registerLicense();

    // Fixed breadcrumb in memory session bugs 
    // Added to clear existing wrong entries in breadcrumb-https://serrala.atlassian.net/browse/SNOW-15802
    sessionStorage.removeItem('breadCrumb');
    sessionStorage.removeItem('permittedMenuItems');
  }
  ngOnDestroy() {
    const appName = 'alevate-navigation';
    const element = document.getElementById(
      `single-spa-application:${appName}`
    );
    element.innerHTML = '';
  }
}
