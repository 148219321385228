<div id="help-widget" class="widget-wrapper" [ngClass]="widgetType == WIDGETS.HELP ? 'help-widget' : ''">
    <div class="widget-container">
        <div class="row ">
            <div class="col-12 widget-header">
                <div class="d-flex flex-row">
                    <ng-container *ngIf="widgetType == WIDGETS.HELP; else elseOverviewWidget">
                        <h5 class="widget-title main-title">{{ 'CORE.powerpanel.help' | translate }}</h5>
                    </ng-container>
                    <ng-template #elseOverviewWidget>
                        <div class="widget-title">{{ 'CORE.powerpanel.help' | translate }}</div>
                    </ng-template>
                </div>
                <!-- <div class="app-version-details">
                    <div>
                        {{ 'CORE.powerpanel.alevateVersion' | translate:{ version: '1.5.3' } }}
                    </div>
                    <div>
                        {{ 'CORE.powerpanel.copyrightSerrala' | translate:{ year: 2020 } }}
                    </div>
                </div> -->
            </div>
        </div>
        <div class="row d-flex justify-content-center">
          {{ "CORE.powerpanel.WORK_IN_PROGRESS" | translate }}
        </div>
    </div>
</div>
