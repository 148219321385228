import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HTTP_RESPONSE_CODES } from '../constants';
import { CoreService } from './../../core/services/core.service';
import {OAuthStorage} from "angular-oauth2-oidc";
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  UPDATE_TOKEN = 'UPDATE_TOKEN';
  constructor( private coreService: CoreService,
               private router: Router,
               private authStorage: OAuthStorage) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do something if useful
        }
        return event;
      }),
      catchError((responseFail: HttpErrorResponse) => {
        if (
          responseFail.status === HTTP_RESPONSE_CODES.UNAUTHORIZED &&
          responseFail.error === this.UPDATE_TOKEN
        ) {
            if (this.authStorage.getItem('access_token')){
             //access token is existing so we can force a logout
              this.coreService.logout();
            }
        }
        if(responseFail.status === HTTP_RESPONSE_CODES.UNAUTHORIZED && responseFail.url.includes('dashboard')) {
          this.router.navigate(['/home'], {queryParams: {isError: true}});
        }
        return throwError(responseFail);
      })
    );
  }
}
