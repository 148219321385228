export class WIDGETCONSTANTS {
  static DIGITALASSITANT = {
    language: 'en-uk',
    sentByUser: 'sentByUser',
    sentByChatBot: 'sentByChatBot',
    noSpeech: 'no-speech',
    object: 'object',
  };
  static Notification = {
    PaymentMessage: 'ANOMALOUS_PAYMENT_MESSAGE_NOTIFICATION',
  };
}
