/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { scan } from 'rxjs/operators';
import { WIDGETS } from '../../menu';
import { WIDGETCONSTANTS } from '../constants/widget-constants';
import { Message } from './model/chat.model';
import { ChatService } from './services/chat.service';

import { AutoUnsubscribe } from '@alevate/serrala-common/decorators';

@AutoUnsubscribe
@Component({
  selector: 'app-digital-assitant',
  templateUrl: './digital-assitant.component.html',
})
export class DigitalAssitantComponent implements OnInit, OnDestroy {
  @Input() widgetType: string;
  WIDGETS = WIDGETS;
  messages: Observable<Message[]>;
  constructor(public chatService: ChatService) {}
  botResponse: Observable<any>;
  ngOnInit() {
    if (this.messages) {
      this.chatService.conversationMessage.unsubscribe();
    }
    this.messages = this.chatService.conversationMessage
      .asObservable()
      .pipe(scan((acc, val) => acc.concat(val)));
    this.chatService.conversationResponse.subscribe({
      next: (res: any) => {
        this.botResponse = res;
      },
    });
  }
  ngOnDestroy() {
    this.chatService.cancelSpeech();
    this.chatService.updateMessages(
      new Message('', WIDGETCONSTANTS.DIGITALASSITANT.sentByUser)
    );
  }
}
