import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConstant } from '@alevate/serrala-common/constants';
import { IBAN_VALIDATION_RESPONSE } from '@app/features/master-data/shared/model/validate-iban.model';
import { Observable } from 'rxjs';

@Injectable()
export class ValidationService { //extends BaseService {
  constructor(private httpClient: HttpClient) {
    // super();
  }

  /**
   * Validate iban
   *
   * @param  {string} iban
   * @returns Observable
   */
  validateIBAN(iban: string): Observable<IBAN_VALIDATION_RESPONSE> {
    const params = new HttpParams().set('iban', iban);
    return this.httpClient.get<IBAN_VALIDATION_RESPONSE>(
      ApiConstant.constants.API_ENDPOINTS.masterdata.validateIBAN,
      { params }
    );
  }
}
