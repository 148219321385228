import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TriggerService } from '@app/core/services/trigger.service';
import { TranslateService } from '@ngx-translate/core';
import { PaymentCardsComponent } from '../payment-cards/payment-cards.component';
import { ChatService } from '../services/chat.service';

import { AutoUnsubscribe } from '@alevate/serrala-common/decorators';

@AutoUnsubscribe
@Component({
  selector: 'app-scripted-flow',
  templateUrl: './scripted-flow.component.html',
})
export class ScriptedFlowComponent implements OnInit {
  @Input() scriptedResponse;
  @Input() botResponse;
  constructor(
    private resolver: ComponentFactoryResolver,
    private triggerService: TriggerService,
    private chatService: ChatService,
    private translateService: TranslateService
  ) {}
  currentCardIndex = 0;
  currentPaymentCard = {};
  components = [];
  currentIndexText = '';

  @ViewChild('scriptedFlowContainer', { read: ViewContainerRef })
  scriptedFlowContainer: ViewContainerRef;
  ngOnInit(): void {
    this.triggerService.getEvent().subscribe({
      next: (res) => {
        if (res.event && res.event.updateScriptedFlow) {
          this.addComponent();
        }
      },
    });
    setTimeout(() => {
      if (this.scriptedResponse.length > 0) {
        this.addComponent();
      }
    });
  }

  /**
   *Add new component
   *
   * @memberof ScriptedFlowComponent
   */
  addComponent(): void {
    if (this.currentCardIndex < this.scriptedResponse.length) {
      const componentFactory = this.resolver.resolveComponentFactory(
        PaymentCardsComponent
      );
      const component =
        this.scriptedFlowContainer.createComponent(componentFactory);
      this.components.push(component);
      this.currentPaymentCard = this.scriptedResponse[this.currentCardIndex];
      component.instance.scriptedData = this.currentPaymentCard;
      component.instance.indexText = this.convertNumbertoText(
        this.currentCardIndex
      );
      if (this.currentCardIndex === 0) {
        component.instance.userAnomolyText = this.botResponse.user_message;
      } else {
        component.instance.userAnomolyText = '';
      }
      this.currentCardIndex++;
      this.chatService.scrollToBottom();
    } else {
      this.chatService.updateScriptedFlow(false);
      this.currentPaymentCard = {};
      this.currentCardIndex = 0;
      this.components = [];
    }
  }
  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.currentPaymentCard = {};
    this.currentCardIndex = 0;
  }
  /**
   * Converts numberto text
   *
   * @param num
   * @returns
   */
  convertNumbertoText(num: number): string {
    const textArray = this.translateService.instant(
      'FEATURES.SERRA.scriptedFlow.textNumbArray'
    );
    const defaultText = this.translateService.instant(
      'FEATURES.SERRA.scriptedFlow.defaultText'
    );
    const txtArray = [
      this.translateService.instant(
        'FEATURES.SERRA.scriptedFlow.startingTexts.text_1'
      ),
      this.translateService.instant(
        'FEATURES.SERRA.scriptedFlow.startingTexts.text_2'
      ),
      this.translateService.instant(
        'FEATURES.SERRA.scriptedFlow.startingTexts.text_3'
      ),
    ];
    const randomIndex = Math.floor(Math.random() * txtArray.length);
    return num <= 15
      ? `${txtArray[randomIndex]} ${textArray[num]} one:`
      : defaultText;
  }
}
