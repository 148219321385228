import {
  AnomalyCells,
  ChartOrientation,
  Constants,
} from '@alevate/serrala-common/constants';
import { calcTW } from '@alevate/serrala-common';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { OpenCloseMenuEventArgs } from '@syncfusion/ej2-angular-navigations';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { LovColumn } from '@app/features/master-data/list-of-values/model/list-of-value-grid.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Internationalization } from '@syncfusion/ej2-base';
import { IbanPipe } from '@alevate/serrala-common/pipes';
import { KeyValue } from '@angular/common';
import { PaginationPayload } from '@alevate/serrala-common/interfaces';

/**
 * @deprecated Please don't use this class anymore, all new utility methods should be put into @alevate/serrala-common/utils
 */
export class Utils {
  constructor(private translateService: TranslateService) {}
  static stopPropogation(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
  }

  static trimTrailingWhiteSpaces(obj: { [x: string]: any }) {
    if (!Array.isArray(obj) && typeof obj !== 'object') {
      return obj;
    }
    return Object.keys(obj).reduce(
      (acc, key) => {
        obj[key] = obj[key] === null ? '' : obj[key];
        acc[key.trim()] =
          typeof obj[key] === 'string'
            ? obj[key].trim()
            : Utils.trimTrailingWhiteSpaces(obj[key]);
        return acc;
      },
      Array.isArray(obj) ? [] : {}
    );
  }

  static changeTextCase(data, convert) {
    if (typeof data === 'string') {
      if (convert === 'upper') {
        return data.toLocaleUpperCase();
      } else {
        return data.toLocaleLowerCase();
      }
    }
  }
  static findIndexToUpdate(newItem) {
    return newItem.id === this;
  }

  static findIndexInArray(arr, obj) {
    if (obj && obj.id) {
      return arr.findIndex((curr) => curr.id === obj.id);
    }
  }
  static emptyObject(obj) {
    Object.keys(obj).forEach((k) => delete obj[k]);
    obj = {};
    return obj;
  }

  static hasClass(el, className) {
    if (el.classList) {
      return el.classList.contains(className);
    }
    return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
  }

  static addClass(el, className) {
    if (el.classList) {
      el.classList.add(className);
    } else if (!Utils.hasClass(el, className)) {
      el.className += ' ' + className;
    }
  }

  static removeClass(el, className) {
    if (el.classList) {
      el.classList.remove(className);
    } else if (Utils.hasClass(el, className)) {
      const reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
      el.className = el.className.replace(reg, ' ');
    }
  }

  static bindLookupData(lookup, idx) {
    if (lookup && lookup.instance) {
      lookup.instance.option('value', idx.toString());
    }
  }

  static removeEmptyList = (obj) => {
    Object.entries(obj).forEach(
      ([key, val]) =>
        (val && typeof val === 'object' && Utils.removeEmptyList(val)) ||
        ((val === null || val === '') && delete obj[key])
    );
    return obj;
  };

  static sanitizeObj(obj) {
    Object.entries(obj).forEach(([key, v]: any) => {
      if (v && typeof v === 'object') {
        Utils.sanitizeObj(v);
      }
      if (
        (v && typeof v === 'object' && !Object.keys(v).length) ||
        v === null ||
        v === undefined ||
        v === ''
      ) {
        if (Array.isArray(obj)) {
          obj.splice(key, 1);
        } else {
          delete obj[key];
        }
      }
    });
    return obj;
  }

  static isEmptyObject(obj) {
    return !obj || Object.keys(obj).length === 0;
  }

  /**
   * Function for getting value of nested property value
   *
   * @param o: object
   * @param s: string
   */
  static getNestedPropertyValue(o: { [x: string]: any }, s: string): any {
    try {
      const a = s.split('.');
      for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        // If k is not defined
        if (k && k in o) {
          o = o[k];
        } else {
          return;
        }
      }
      return o;
    } catch (err) {
      return undefined;
    }
  }

  static calculateWidthOfField(value: string, font) {
    return calcTW(value, font);
  }

  /**
   *  Compare two objects
   */
  static compareObjects(object1: object, object2: object): boolean {
    const objKeys1 = Object.keys(object1);
    const objKeys2 = Object.keys(object2);
    let isEqual = true;
    if (objKeys1.length !== objKeys2.length) {
      isEqual = false;
      return isEqual;
    }
    objKeys1.forEach((key) => {
      if (!(object2.hasOwnProperty(key) && object1[key] === object2[key])) {
        isEqual = false;
      }
    });
    return isEqual;
  }

  /**
   *  method for setting date in perticular format
   */
  static convertDateIntoSpecificFormat(dateStr, type, separator = '/') {
    let dd;
    let MM;
    let yy;
    const newDate = new Date();
    if (type === Constants.DATETYPE.datetime) {
      const [date, time] = dateStr.split(' ');
      [dd, MM, yy] = date.split(separator);
      const [hh, mm, ss] = time.split(':');
      newDate.setHours(hh);
      newDate.setMinutes(mm);
      newDate.setSeconds(ss);
    } else {
      [dd, MM, yy] = dateStr.split(separator);
    }
    newDate.setFullYear(yy, MM - 1, dd);
    return newDate;
  }
  /**
   *  formatting ISO Dates to dd/mm/yyyy hh:mm:ss format
   *
   *
   */
  static convertUnixDates(dateStr: string): string {
    const newDateObj = new Date(dateStr);
    newDateObj.setTime(
      newDateObj.getTime() + newDateObj.getTimezoneOffset() * 60 * 1000
    );
    if (!Utils.isDateValid(newDateObj)) return dateStr;
    const toMonth = newDateObj.getMonth() + 1;
    const toMonthProcessed = (toMonth < 10 ? '0' : '') + toMonth;
    const toYear = newDateObj.getFullYear();
    const toDate = newDateObj.getDate();
    const toDateProcessed = (toDate < 10 ? '0' : '') + toDate;
    const toHours = newDateObj.getHours();
    const toHoursProcessed = (toHours < 10 ? '0' : '') + toHours;
    const toMin = newDateObj.getMinutes();
    const toMinProcessed = (toMin < 10 ? '0' : '') + toMin;
    const toSec = newDateObj.getSeconds();
    const toSecProcessed = (toSec < 10 ? '0' : '') + toSec;
    return `${toDateProcessed}/${toMonthProcessed}/${toYear} ${toHoursProcessed}:${toMinProcessed}:${toSecProcessed}`;
  }

  static isDateValid(date: Date): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }
  /**
   * Get current date string to current date object like [Thu Nov 26 2020 00:00:00 GMT+0530 (India Standard Time)]
   *
   * @param {string} strDate
   */
  static getMidnightDateTimeObj(strDate: string) {
    const date = new Date(strDate);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const n = date.getTimezoneOffset();
    const timezone = n / -60;
    if (timezone > 0) {
      date.setTime(date.getTime() - userTimezoneOffset);
    } else {
      date.setTime(date.getTime() + userTimezoneOffset);
    }
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  /**
   * Get Current passed date object with converting to 12:00 am time with UTC timezone offset remove or add accordingly
   * for example:
   *
   * @param {Date} strDate
   */
  static getUTCMidnightDateTimeObj(dateObj: Date) {
    const userTimezoneOffset = dateObj.getTimezoneOffset() * 60000;
    const n = dateObj.getTimezoneOffset();
    const timezone = n / -60;
    if (timezone > 0) {
      dateObj.setTime(dateObj.getTime() - userTimezoneOffset);
    } else {
      dateObj.setTime(dateObj.getTime() + userTimezoneOffset);
    }
    return dateObj;
  }

  /**
   * Method for joining fields value by separator
   *
   * @param fields
   * @param separator
   */
  static joinFieldsValueBySeparator(fields, separator = ' ') {
    return fields.filter((val) => val).join(separator);
  }

  /**
   * To convert parameter value into Locale Upper case
   *
   * @param str:any
   */
  static covertToLocaleUpperCase(str: any) {
    return str ? str.toString().toLocaleUpperCase() : null;
  }
  static customiseCell(args, hasAnomaly) {
    const tempData = args.data;
    let anomaly = hasAnomaly;
    if (tempData['anomalous']) {
      args.cell.classList.add('e-row-anomalies');
      if (
        args.column.field === AnomalyCells.PartnerName ||
        args.column.field === AnomalyCells.Currency ||
        args.column.field === AnomalyCells.Amount
      ) {
        args.cell.classList.add('e-cell-anomalies');
        if (!anomaly) {
          // condition to apply class only one time
          const elem = document.getElementById(AnomalyCells.PartnerName);
          elem.classList.add('e-column-anomalies');

          const elem2 = document.getElementById(AnomalyCells.Currency);
          elem2.classList.add('e-column-anomalies');

          const elem3 = document.getElementById(AnomalyCells.Amount);
          elem3.classList.add('e-column-anomalies');
          anomaly = true;
        }
      }
    }
    return anomaly;
  }

  /**
   * Return Dropdown array values with localized text
   *
   * @param translatorService instance of Translator Service
   * @param dropdownObjArr Array of Objects defined as dropdown values and text
   * @param textKeyName as String if different textKeyName is given
   *
   */
  static getLocalizedDropdownArr(
    translatorService,
    dropdownObjArr,
    textKeyName = 'text'
  ) {
    const tempArr = [];
    dropdownObjArr.forEach((element) => {
      if (element[textKeyName]) {
        element[textKeyName] = translatorService.instant(element[textKeyName]);
      }
      tempArr.push(element);
    });
    return tempArr;
  }

  /**
   * Get Grid height to set as client height
   *
   * @param decraseHeightBy as number
   */
  static getGridHeight(decraseHeightBy = 200): number {
    let gridHeight;
    gridHeight = document.documentElement.clientHeight - decraseHeightBy;
    if (gridHeight < 600) {
      gridHeight = 600;
    }
    return gridHeight;
  }

  /**
  function which we can use for getting dynamic messages and url like sprintf function of php
  for example sprintf('Latitude: %s, Longitude: %s, [Count: %d', 41.847, -87.661, 'two'])
  we can use %s or %d to pass as an argument to be appended
   **/
  static sprintf(string, argsArr) {
    let args = argsArr,
      i = 0;
    return string.replace(/%((%)|s|d)/g, (m) => {
      // m is the matched format, e.g. %s, %d
      let val = null;
      if (m[2]) {
      } else {
        val = args[i];
        // A switch statement so that the formatter can be extended. Default is %s
        switch (m) {
          case '%d':
            val = parseFloat(val);
            if (isNaN(val)) {
              val = 0;
            }
            break;
          default:
        }
        i++;
      }
      return val;
    });
  }

  /**
   * Method for compare two dates
   *
   * @param slaReceivedTimeStamp : timestamp
   * @param receivedTimeStamp : timestamp
   */
  static compareDates(
    slaReceivedTimeStamp: string,
    receivedTimeStamp: Date
  ): boolean {
    if (slaReceivedTimeStamp && receivedTimeStamp) {
      if (+slaReceivedTimeStamp >= +receivedTimeStamp) {
        return true;
      } else {
        return false;
      }
    }
  }
  /**
   * Formats numbers
   *
   * @param args;
   * @returns;
   * refer link for notion linting issue
   * https://github.com/microsoft/TypeScript/issues/36533#issuecomment-607081387
   */
  static formatNumbers(data) {
    const args = data;
    if (
      args.axis.orientation === ChartOrientation.Horizontal ||
      args.axis.orientation === ChartOrientation.Vertical
    ) {
      if (parseInt(args.text, 10)) {
        args.text = new Intl.NumberFormat('en-EN', {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          notation: 'compact',
          compactDisplay: 'short',
        }).format(+args.text);
      }
    }
    return args;
  }

  /**
   * Start of Grid Common functionality methods
   */
  static dataBound(grid, translateService): void {
    const filterbar = grid?.element?.querySelector('.e-filterbar');
    if (filterbar) {
      [].slice.call(filterbar.children).forEach((item) => {
        const element = item.querySelector('input.e-input');
        if (element) {
          item.querySelector('input.e-input').placeholder =
            translateService.instant('SHARED.ACTIONS.SEARCH');
        }
      });
    }
  }

  /**
   * @description Common method to Export pdf only visible columns
   * @param grid {Object instance of grid}
   * @param fileName {string}
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static exportPdf(grid, fileName: string): void {
    const colLength: number = grid.getColumns().length;
    const cols = grid.getColumns();
    const hiddenColumns = [];
    for (let i = 0; i < colLength; i++) {
      if (!cols[i].field) {
        cols[i].visible = false;
        hiddenColumns.push(i);
      }
      if (cols[i].field) {
        if (cols[i].field === LovColumn.ACTIONS) {
          cols[i].visible = false;
        }
        hiddenColumns.push(i);
      }
    }
    const pdfExportProperties = {
      fileName,
    };
    const exportPdf = grid.pdfExport(pdfExportProperties);
    if (exportPdf) {
      exportPdf.then(() => {
        hiddenColumns.forEach((elementIndex) => {
          cols[elementIndex].visible = true;
        });
      });
    }
  }

  /**
   * @description Common method to Export Excel only visible columns
   * @param grid {Object instance of grid}
   * @param fileName {string}
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static exportExcel(grid, fileName: string) {
    const colLength: number = grid.getColumns().length;
    const cols = grid.getColumns();
    const hiddenColumns = [];
    for (let i = 0; i < colLength; i++) {
      if (!cols[i].field) {
        cols[i].visible = false;
        hiddenColumns.push(i);
      }

      if (cols[i].field) {
        if (cols[i].field === LovColumn.ACTIONS) {
          cols[i].visible = false;
        }
        hiddenColumns.push(i);
      }
    }
    const excelExportProperties = {
      fileName,
    };
    const exportExel = grid.excelExport(excelExportProperties);
    if (exportExel) {
      exportExel.then(() => {
        hiddenColumns.forEach((elementIndex) => {
          cols[elementIndex].visible = true;
        });
      });
    }
  }

  /**
   * @description Method to set datepicker popup position
   * @param  {OpenCloseMenuEventArgs} args
   * @param  {string} datePickerElement
   * @returns void
   */
  static setDatePickerPopupPosition(
    args: OpenCloseMenuEventArgs,
    datePickerElement: string
  ): void {
    setTimeout(() => {
      /*
       *  with a time of 0 or empty ms, the function is not invoked right away.
       *  Instead, it is placed on a queue to be invoked “as soon as possible” after any currently pending event handlers finish running.
       *  Hence, It is been added into set time out, so that open event will execute & pop up will be reedernd in DOM.
       *  After it's rendering, we are shifting pop up to the right position.
       *  Due to settimeout, Changing of Position is not visible.
       */
      const popupWidth = args[`popup`].element.offsetWidth;
      const popUpleftPos = args[`popup`].element.style.left.split('px')[0];
      const dynamicWidth =
        document.getElementById(datePickerElement).offsetWidth - popupWidth;
      const newLeftPos = +popUpleftPos + dynamicWidth;
      args[`popup`].element.style.left = newLeftPos + 'px';
    });
  }

  /**
   * Record Save Failed Function
   *
   * @static
   * @param {HttpErrorResponse} errorResp
   * @param {string} id: Record ID
   * @param {TranslateService} [translateService]
   * @return {*}  {string}
   * @memberof Utils
   */
  static onRecordSaveFail(
    errorResp: HttpErrorResponse,
    id: string,
    translateService?: TranslateService
  ): string {
    let msgStr = '';
    if (errorResp.error) {
      const errorObj = errorResp.error;
      if (Array.isArray(errorObj.errors)) {
        errorObj.errors.forEach((errorMsg) => {
          msgStr += errorMsg + '  ';
        });
      } else {
        msgStr = errorResp.error.message;
      }
    } else {
      const message = id
        ? 'SHARED.MESSAGES.RECORD_UPDATE_ERROR'
        : 'SHARED.MESSAGES.RECORD_ADD_ERROR';
      msgStr = translateService.instant(message);
    }
    return msgStr;
  }

  /**
   * Locale number conversion for 1000 seperator
   * Locale: de-German
   *
   * @static
   * @param {Number} num
   * @return {*}  {string}
   * @memberof Utils
   */
  static convertNumberToLocale(num: number): string {
    const amount = Number(num).toFixed(2);
    return Number(amount).toLocaleString('de');
  }

  /**
   * Method to convert enum to array
   *
   * @param  {} enumme
   * @returns string
   */
  static enumToArray(enumme): string[] {
    return Object.keys(enumme).map((key) => enumme[key]);
  }

  /**
   * Attaches onclick event handler with browse link
   */
  static attachDialogBrowseClickHandler() {
    document.getElementById('browseLink').onclick = () => {
      document
        .getElementsByClassName('e-file-select-wrap')[0]
        .querySelector('button')
        .click();
      return false;
    };
  }

  /**
   * Trigger validation of reactive form fields
   *
   * @param  {FormGroup} formGroup
   * @returns void
   */
  static validateAllFormFields(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Set 'No Records to Display' message position in movable section of grid
   *
   * @param  {GridComponent} grid
   * @returns void
   */
  static setNoRecordsToDisplayMessagePosition(
    gridInstance: GridComponent
  ): void {
    const movablecontent = gridInstance.element.querySelector(
      '.e-gridcontent .e-content .e-movablecontent td'
    ) as HTMLElement;
    const frozencontent = gridInstance.element.querySelector(
      '.e-frozencontent .e-emptyrow td'
    ) as HTMLElement;
    // change the innertext
    if (frozencontent) {
      movablecontent.innerText = (frozencontent as HTMLElement)?.innerText;
      frozencontent.innerText = '';
    }
  }

  /**
   * Get element offset position
   *
   * @param  {} el
   */
  static getDocumentOffsetPosition(el) {
    const position = {
      top: el.offsetTop,
      left: el.offsetLeft,
    };
    if (el.offsetParent) {
      const parentPosition = this.getDocumentOffsetPosition(el.offsetParent);
      position.top += parentPosition.top;
      position.left += parentPosition.left;
    }
    return position;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static isNullOrUndefined(value): boolean {
    return value === null || value === undefined;
  }

  /**
   * Formats chart values in the format of thousand and million and append label to values
   * accordingly
   *
   * @paramargs
   * @returns
   */
  static formatChartValues(args): string {
    if (args.axis.name === 'primaryYAxis') {
      const intl: Internationalization = new Internationalization();
      const nParser = intl.getNumberParser({
        format: 'N2',
        useGrouping: true,
      });
      const value: number = nParser(args.text);
      if (value >= 1000000000) {
        args.text = (value / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
      } else if (value >= 1000000) {
        args.text = (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      } else if (value >= 1000) {
        args.text = (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      } else if (value <= -1000000000) {
        args.text = (value / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
      } else if (value <= -1000000) {
        args.text = (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      } else if (value <= -1000) {
        args.text = (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      if (value === 0) {
        args.text = '0';
      }
    }
    return args;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static ibanFormatter(args: any) {
    let serachValue = args.currentFilterObject.value;
    serachValue = serachValue.replace(/[-\ ]/g, '');
    const regex = new RegExp('^[A-Za-z]{2}[0-9]{2}$');
    const ibanFormat = serachValue.slice(0, 4);
    if (regex.test(ibanFormat)) {
      serachValue = new IbanPipe().transform(serachValue);
      args.currentFilterObject.value = serachValue;
    }
    return args;
  }

  /**
   * Key desc order of utils;
   */
  static keyDescOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => (a.key > b.key ? -1 : b.key > a.key ? 1 : 0);

  /**
   * Calculates dialog position;
   *
   * @param importButtonId;
   * @returns dialog position;
   */
  static calculateDialogPosition(importButtonId: string): {
    importDialogLeft: number;
    importDialogTop: number;
  } {
    const positionY =
      window.scrollY +
      document.getElementById(importButtonId)?.getBoundingClientRect()?.top;
    const positionX =
      window.scrollX +
      document.getElementById(importButtonId)?.getBoundingClientRect()?.left;
    const buttonWidth = document.getElementById(importButtonId)?.clientWidth;
    const buttonHeight = document.getElementById(importButtonId)?.clientHeight;
    const importDialogTop = positionY + buttonHeight;
    const importDialogLeft = positionX + buttonWidth;
    return { importDialogLeft, importDialogTop };
  }

  /**
   * Gets language header value as required for backend
   *
   * @param languageText;
   * @returns backend centric header value;
   */
  static getLanguageHeaderValue(languageText: string): string {
    switch (languageText) {
      case 'en': {
        return 'en-US';
      }
      case 'de': {
        return 'de-DE';
      }
      default:
        return 'en-US';
    }
  }

  /**
   * count progress label of entity files
   */
  static getprogressLabel(
    entityName: string,
    totalCount: number,
    sucessCount: number,
    failureCount: number
  ): { textAlignment: string; text: string } | number {
    if (totalCount && (sucessCount || failureCount)) {
      const word = entityName.slice(-1);
      if (totalCount > 1) {
        if (word === 'y') {
          entityName = entityName.replace(/.$/, 'ies');
        } else {
          entityName = entityName + 's';
        }
      }
      const percentage = Math.round((sucessCount / totalCount) * 100);
      return {
        textAlignment: 'Center',
        text: `${sucessCount} out of ${totalCount} ${entityName} Successful (${percentage}%)`,
      };
    } else {
      return 0;
    }
  }

    /**
   *
   * Get the default params of grid
   * @static
   * @param {Object} original
   * @returns {*}  {PaginationPayload}
   * @memberof utils
   */
    static getDefaultPaginationParams(original: Object): PaginationPayload {
      const url = new URLSearchParams(original['url']);
      const filter = url.get('$filter');
      let columnFilter = filter ? filter : [];
      const gridFilter =
        Object.keys(original['pvtData']).length > 0
          ? original['pvtData']
          : undefined;
      const top = url.get('$top');
      const skip = url.get('$skip');
      const orderby = url.get('$orderby') ? url.get('$orderby') : undefined;
  
      if (filter && Object.keys(original['pvtData']).length !== 0) {
        const filterArray = filter.split(' and ');
        filterArray.splice(-1);
        if (filterArray.length >= 1) {
          columnFilter = filterArray.join(' and ');
        } else {
          columnFilter = [];
        }
      }
  
      return {
        gridFilter,
        top,
        skip,
        orderby,
        columnFilter,
      } as PaginationPayload;
    }

  /**
   *
   * Remove duplicate values
   * @static
   * @param {Object} original
   * @returns {*}  {PaginationPayload}
   * @memberof Utils
   */
  static removeDuplicatesByKey(arr) {
    const seen = new Set();
    if (arr.length && Array.isArray(arr)) {
      return arr.filter((item) => {
        const combinedKey = `${item.field}`;
        if (!seen.has(combinedKey)) {
          seen.add(combinedKey);
          return true;
        }
        return false;
      });
    } else {
      return [];
    }
  }
   /**
   *
   * Updated latest value of column filter array
   * @static
   * @param {Object} original
   * @returns {*}  {PaginationPayload}
   * @memberof Utils
   */
  static updateOrAddEntry(array: Array<{field: string, value: string, operator: string}> | Array<any>, newData:{field: string, value: string, operator: string}):void {
    const fieldToUpdate = newData.field;
    const existingEntryIndex = array.findIndex(
      (entry) => entry.field === fieldToUpdate
    );
    if (existingEntryIndex !== -1) {
      // Update the value for the existing entry
      array[existingEntryIndex].value = newData.value;
      array[existingEntryIndex].operator = newData.operator;
    } else {
      // Add the new entry if it doesn't exist
      array.push(newData);
    }
  }
}
