/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { POWER_PANEL_BOTTOM_MENU } from '@app/layout/off-sidebar/menu';
import { SpeechRecognitionService } from '../services/speech-recognition.service';
import { Message } from '../model/chat.model';
import { WIDGETCONSTANTS } from '../../constants/widget-constants';
import { ChatService } from '../services/chat.service';
import { TriggerService } from '@app/core/services/trigger.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SerraConstants } from '@app/layout/off-sidebar/widgets/serra-constants';
import { AmountFormatPipe } from '@alevate/serrala-common/pipes';
import { TranslateService } from '@ngx-translate/core';

import { AutoUnsubscribe } from '@alevate/serrala-common/decorators';

@AutoUnsubscribe
@Component({
  selector: 'app-chat-footer',
  templateUrl: './chat-footer.component.html',
  styleUrls: ['./chat-footer.component.scss'],
})
export class ChatFooterComponent implements OnInit {
  @Output() showBotPanel = new EventEmitter();
  isScripted: boolean;
  constructor(
    private speechRecognService: SpeechRecognitionService,
    private chatService: ChatService,
    private triggerService: TriggerService,
    private amountFormatPipe: AmountFormatPipe,
    private translateService: TranslateService
  ) {}
  powerPanelBottomMenu = POWER_PANEL_BOTTOM_MENU;
  disableVoiceButton = false;
  serraUserMessage = '';
  ngOnInit(): void {
    this.chatService.isScripted.subscribe({
      next: (res) => {
        this.isScripted = res;
      },
    });
    this.triggerService.getEvent().subscribe({
      next: (res) => {
        if (res.event && res.event.serraMessage) {
          const message = res.event.serraMessage;
          this.communicateMessages(message);
          this.showBotPanel.next(this.powerPanelBottomMenu.key);
        }
      },
    });
  }

  /**
   * Shows bot component
   *
   * @param key
   */
  showBotComponent(key) {
    this.chatService.updateBotState({ show: true, param: key });
  }
  /**
   * Input{{ user message }}
   *
   * @param event
   * @param key
   */
  inputUserMessage(event, key: string) {
    if (event) {
      this.showBotPanel.next(key);
    }
  }
  /**
   * Listens when user press on record
   */
  listen() {
    this.disableVoiceButton = false;
    this.speechRecognService.record().subscribe({
      // listener
      next: (value) => {
        this.serraUserMessage = value;
        this.sendMessage();
      },
      // errror
      error: (err) => {
        console.log(err);
        if (err.error === WIDGETCONSTANTS.DIGITALASSITANT.noSpeech) {
          this.destroySpeech();
        }
      },
      // completion
      complete: () => {
        this.disableVoiceButton = true;
        this.destroySpeech();
      },
    });
  }

  /**
   * Sends message
   */
  sendMessage(message?: string): void {
    if (this.serraUserMessage.trim().length !== 0) {
      const userMessage = new Message(
        this.serraUserMessage,
        WIDGETCONSTANTS.DIGITALASSITANT.sentByUser
      );
      this.chatService.updateMessages(userMessage);
      this.communicateMessages(this.serraUserMessage);
      this.triggerService.setEvent({ messageSent: true });
      this.serraUserMessage = '';
    }
  }
  /**
   * Communicates messages;
   *
   * @param message;
   */
  communicateMessages(message: string): void {
    let serraResponse = '';
    this.chatService.converse(message).subscribe({
      next: (res: any) => {
        if (res) {
          this.chatService.updateSerraResponse(res);
          if (
            typeof res.serra_response === WIDGETCONSTANTS.DIGITALASSITANT.object
          ) {
            if (Array.isArray(res.serra_response.concatenate_response)) {
              serraResponse = '';
            } else {
              switch (res.serra_response.intent_name) {
                case SerraConstants.INTENT_NAME.TOP_ADF_FILES:
                  serraResponse =
                    res.serra_response.concatenate_response.message;
                  break;
                case SerraConstants.INTENT_NAME.CASH_POSITION:
                  if (res.serra_response.concatenate_response) {
                    if (res.serra_response.concatenate_response.message) {
                      serraResponse =
                        res.serra_response.concatenate_response.message;
                      if (res.serra_response.concatenate_response.balance) {
                        serraResponse += `${this.amountFormatPipe.transform(
                          res.serra_response.concatenate_response.balance
                        )}  ${
                          res.serra_response.concatenate_response.currency
                        }`;
                      } else {
                        serraResponse += `0 ${res.serra_response.concatenate_response.currency}`;
                      }
                    } else {
                      serraResponse = this.translateService.instant(
                        'SHARED.ERROR_MESSAGES.ERROR_OCCURRED'
                      );
                    }
                  }
                  break;

                default:
                  serraResponse = res.serra_response.concatenate_response;
                  break;
              }
            }
          } else {
            serraResponse = res.serra_response;
          }
          this.chatService.convertTextoVoice(serraResponse);
          this.chatService.updateMessages(
            new Message(
              serraResponse,
              WIDGETCONSTANTS.DIGITALASSITANT.sentByChatBot
            )
          );
        }
      },
      error: (err: HttpErrorResponse) => {
        if (err.status === 401) {
          serraResponse = err.error;
          this.chatService.convertTextoVoice(serraResponse);
          this.chatService.updateMessages(
            new Message(
              serraResponse,
              WIDGETCONSTANTS.DIGITALASSITANT.sentByChatBot
            )
          );
        }
      },
    });
  }

  /**
   * Destroys speech
   */
  destroySpeech() {
    this.speechRecognService.destroySpeechObject();
  }
}
