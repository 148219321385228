
import { enableProdMode, NgZone } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { singleSpaAngular, getSingleSpaExtraProviders } from 'single-spa-angular';
import { singleSpaPropsSubject } from './single-spa/single-spa-props';
import 'zone.js/dist/zone';
import { Constants } from '@app/core/constants';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AlevateNavigationModule } from './app/alevate-navigation.module';
import { environment } from './environments/environment';

// CSS issue of single-spa with angular 15 https://github.com/single-spa/single-spa-angular/issues/463
/// @ts-ignore
require('./styles.scss?ngGlobalStyle');

fetch(environment.featureToggleUrl).then(res => res.json()).then((resp) =>{
  const cdnUrl = resp?.common?.systemSettings?.cdnUrl;
  sessionStorage.setItem(Constants.CDN.URL, cdnUrl);
  let fontList =[
    new FontFace('NotoSans', `url(${cdnUrl+'/alevate-static-files/fonts/' + Constants.CDN.VERSION + '/NotoSans-Regular.ttf'}) format("truetype")`,
    {
      'weight': '400',
      'style': 'normal',
      'stretch': 'normal'
    }
    ),
    new FontFace('NotoSans', `url(${cdnUrl+'/alevate-static-files/fonts/' + Constants.CDN.VERSION + '/NotoSans-Italic.ttf'}) format("truetype")`,
    {
      'weight': '400',
      'style': 'italic',
      'stretch': 'normal'
    }
    ),
    new FontFace('NotoSans', `url(${cdnUrl+'/alevate-static-files/fonts/' + Constants.CDN.VERSION + '/NotoSans-Bold.ttf'}) format("truetype")`,
    {
      'weight': '700',
      'style': 'normal',
      'stretch': 'normal'
    }
    ),
    new FontFace('NotoSans', `url(${cdnUrl+'/alevate-static-files/fonts/' + Constants.CDN.VERSION + '/NotoSans-BoldItalic.ttf'}) format("truetype")`,
    {
      'weight': '700',
      'style': 'italic',
      'stretch': 'normal'
    }
    ),
    new FontFace('serrala', `url(${cdnUrl+'/alevate-static-files/fonts/' + Constants.CDN.VERSION + '/icons/serrala.ttf'}) format("truetype")`,
    {
      'weight': 'normal',
      'style': 'normal',
      'display' : 'block'
    }
    )
  ];
  
  fontList.forEach(font => {
    (document as any).fonts.add(font);
      font.load(); 
  });
}).catch(error => {
  console.warn('CDN url not found', error);
});

if (environment.production) {
  enableProdMode();
}

const lifecycles = singleSpaAngular({
  bootstrapFunction: singleSpaProps => {
    singleSpaPropsSubject.next(singleSpaProps);
    return platformBrowserDynamic(getSingleSpaExtraProviders()).bootstrapModule(AlevateNavigationModule);
  },
  template: '<alevate-navigation />',
  Router,
  NavigationStart,
  NgZone: NgZone,
});

export const bootstrap = lifecycles.bootstrap;
export const mount = lifecycles.mount;
export const unmount = lifecycles.unmount;
