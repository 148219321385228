import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { BaseService } from '@alevate/serrala-common/services';
import { WIDGETAPICONSTANTS } from '../../constants/widget-api-constants';

@Injectable()
export class ChatService extends BaseService {
  conversationMessage = new BehaviorSubject([]);
  conversationResponse = new Subject();
  isScripted = new BehaviorSubject(false);
  showBotPanel = new BehaviorSubject<BotState>({ show: false, param: '' });
  myTimeout;
  private speechSynth: SpeechSynthesis;
  constructor() {
    super();
    this.speechSynth = window.speechSynthesis;
  }
  endPoint = WIDGETAPICONSTANTS.constants.ENDPOINTS.botService.communication;

  // Sends and receives messages via API
  converse(msg: string) {
    return this.httpClient.get(`${this.endPoint}?message=${msg}`, {
      observe: 'body',
    });
  }

  updateMessages(message) {
    this.conversationMessage.next([message]);
  }
  updateSerraResponse(response) {
    this.conversationResponse.next(response);
  }

  convertTextoVoice(text) {
    this.cancelSpeech();
    let globalThis = this;
    this.myTimeout = setTimeout(this.myTimer, 10000);
    const utterance = new SpeechSynthesisUtterance();
    const voices = this.speechSynth.getVoices();
    utterance.voice =
      voices.find((voice) => voice.name === 'Google UK English Female') ||
      voices[2];
    utterance.text = text;
    utterance.rate = 1;
    utterance.pitch = 0;
    const myInterval = setInterval(() => {  this.speechSynth.pause(); speechSynthesis.resume(); }, 5000);
    utterance.onend =  function() { clearInterval(myInterval); clearTimeout(globalThis.myTimeout); };
    this.speechSynth.speak(utterance);
  }

  cancelSpeech() {
    this.speechSynth.cancel();
  }
  /**
   * Updates scripted flow
   *
   * @param flag:boolean;
   */
  updateScriptedFlow(flag) {
    this.isScripted.next(flag);
  }
  /**
   * Updates bot state
   *
   * @param state
   */
  updateBotState(state) {
    this.showBotPanel.next(state);
  }
  removeAnamoly(url) {
    return this.httpClient.delete(url);
  }
  updateAnamoly(url) {
    return this.httpClient.put(url, {});
  }
  scrollToBottom() {
    setTimeout(() => {
      const element = document.getElementById('powerPanelDetails');
      element.scrollTop = element.scrollHeight - element.clientHeight;
    });
  }
  myTimer() {
    let globalThis = this;
    this.speechSynth.pause();
    this.speechSynth.resume();
    this.myTimeout = setTimeout(globalThis.myTimer, 10000);
}
}
export interface BotState {
  show: boolean;
  param?: string;
}
