<ng-container>
    <serrala-bar-chart *ngIf="getChartType() === SerraConstants.CHART_TYPE.BAR"
                       [dataSource]="getChartData()"
                       [barChart]="getChartConfig()">
    </serrala-bar-chart>
</ng-container>
<ng-container *ngIf="getChartType() === SerraConstants.CHART_TYPE.LINE">
    <serrala-stacked-chart #serralaStackedChart
        [stackedChart]="getChartData()"
        (axisLabelRenderEvent)='formatChartBalanceValues($event)' >
    </serrala-stacked-chart>
</ng-container>
