// Define application constants
/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export class ApiConstant {
  static constants = {
    API_ENDPOINTS: {
      masterdata: {
        currencies: '/backend/masterdata/api/masterdata/currencies',
        countries: '/backend/masterdata/api/masterdata/countries',
        companies: '/backend/masterdata/api/masterdata/companies',
        accounts: '/backend/masterdata/api/masterdata/accounts',
        accountsByType:
          '/backend/masterdata/api/masterdata/accounts/accountByType',
        banks: '/backend/masterdata/api/masterdata/banks',
        masterbanks: '/backend/masterdata/api/masterdata/masterbanks',
        getmasterbanks: '/backend/masterdata/api/masterdata/masterbanks/masterbankworklist',
        searchmasterbanks: '/backend/masterdata/api/masterdata/masterbanks/search',
        validateBic: '/backend/masterdata/api/masterdata/masterbanks/validate',
        getBankCodes: '/backend/masterdata/api/masterdata/banks/bankcodes',
        status: '/backend/masterdata/api/masterdata/states',
        partner: '/backend/masterdata/api/masterdata/partners/partners-wrapper',
        getExistingParty: '/backend/masterdata/api/masterdata/partners/party',
        deletePartner: '/backend/masterdata/api/masterdata/partners',
        deletePartnerAccount: `/backend/masterdata/api/masterdata/partners/partnerid/partner-account`,
        partyLookup: `/backend/masterdata/api/masterdata/partners/party-lookup`,
        partyLookByCurrencyCode:
          '/backend/masterdata/api/masterdata/partners/partner-accounts-by-currencycode',
        accountLookByCurrencyCode:
          '/backend/masterdata/api/masterdata/accounts/accounts-by-currencycode',
        stausInfoCategory:
          '/backend/masterdata/api/masterdata/status-infocategory',
        statusCode: '/backend/masterdata/api/masterdata/status-code',
        reasonCode: '/backend/masterdata/api/masterdata/reason-code',
        externalServiceProvider:
          '/backend/masterdata/api/masterdata/collectionagency',
        customers: '/backend/masterdata/api/masterdata/customers',
        lov: '/backend/masterdata/api/masterdata/listofvalues/value',
        cashflowtype: '/backend/masterdata/api/masterdata/cashflowtype',
        parties: '/backend/masterdata/api/masterdata/parties',
        unknownAccounts: '/backend/masterdata/api/masterdata/missingmasterdata',
        fxRates:
          '/backend/masterdata/api/masterdata/currencyconversion/rateTypes',
        fxRatesDetails: '/backend/masterdata/api/masterdata/currencyconversion',
        masterdataLov:
          '/backend/masterdata/api/masterdata/listofvalues/value?lovName=%s',
        fxRatesImport:
          '/backend/masterdata/api/masterdata/currencyconversion/upload',
        fxRatesEffectiveDates:
          '/backend/masterdata/api/masterdata/currencyconversion/effectiveDates',
        fxRatesImportCheckDuplicate:
          '/backend/masterdata/api/masterdata/currencyconversion/upload/duplicate',
        externalSystem: '/backend/masterdata/api/masterdata/external-system',
        validateIBAN:
          '/backend/masterdata/api/masterdata/accounts/validate-iban',
        collectionStatus:
          '/backend/masterdata/api/masterdata/collection-status',
        cashflowlevel: `/backend/masterdata/api/masterdata/cashflowlevels`,
        cashflowLevelSystemKey: `/backend/masterdata/api/masterdata/cashflowlevels/systemKeys`,
        import: '/backend/masterdata/api/masterdata/import',
        liquidityType: `/backend/masterdata/api/masterdata/liquiditytype`,
        liquidityTypeBulkDelete: `/backend/masterdata/api/masterdata/liquiditytype/bulk/delete`,
        liquidityTypeCreate: `/backend/masterdata/api/masterdata/liquiditytype/create`,
        accountDetailsByBank:
          '/backend/masterdata/api/masterdata/accounts/accountDetailsByBank',
        customerMatchingConfiguration:
          '/backend/masterdata/api/masterdata/matchingconfiguration',
        messageImportLogs: '/backend/masterdata/api/masterdata/import/logs',
        getMasterbanks: '/backend/masterdata/api/masterdata/masterbanks/all',
        getBicCodes: '/backend/masterdata/api/masterdata/masterbanks/sortedbankcodes',
      },

      adchcollection: {
        allProductsLov: '/backend/masterdata/api/masterdata/listofvalues',
        collectionServiceLov:
          '/backend/adchcollection/api/creditcollection/listofvalues',
        fileImport:
          '/backend/adchcollection/api/creditcollection/listofvalues/fileimport',
        externalSystem:
          '/backend/adchcollection/api/creditcollection/external-system',
        collectionsLov:
          '/backend/adchcollection/api/creditcollection/listofvalues/value',
        lovByName:
          '/backend/adchcollection/api/creditcollection/listofvalues/lovName',
      },

      dashboard: {
        getAllWidget: `/backend/paymentinput/api/paymentformat/dashboard`,
        getAllPaymentMsgWidget:
          '/backend/paymentinput/api/paymentformat/dashboard/paymentmessage',
        getAllPaymentItemsWidget:
          '/backend/paymentinput/api/paymentformat/dashboard/paymentitems',
        getAllBankWidget: `/backend/bankstatus/api/bankstatus/dashboard`,
        getAllAccountWidget: `/backend/accountStatementService/api/account-statement/dashboard`,
        getASMWidget: `/backend/accountStatementService/api/account-statement/dashboard/message`,
        getASWidget: `/backend/accountStatementService/api/account-statement/dashboard/statement`,
        getItemWidget: `/backend/accountStatementService/api/account-statement/dashboard/item`,
      },
      paymentInput: {
        submitPaymentInput: `/backend/paymentinput/api/paymentservice`,
        getPaymentFormatDefination: `/backend/paymentinput/api/paymentformat/definition/`,
        getTemplateList: `/backend/paymentinput/api/paymentformat/template/format/`,
        templateCRUDAPI: '/backend/paymentinput/api/paymentformat/template/',
        editPaymentInput: `/backend/paymentinput/api/paymentformat/payment/`,
        getPaymentFiles: `/backend/paymentinput/api/paymentformat/payment/file/search`,
        getPaymentItems: `/backend/paymentinput/api/paymentformat/payment/items/search`,
        changeBulkStatus: `/backend/paymentinput/api/paymentformat/payment/file/file-status`,
        acceptDuplicate:
          '/backend/paymentinput/api/paymentformat/payment/file/accept-duplicate/',
        retryFileImport:
          '/backend/paymentinput/api/paymentformat/payment/file/retry-import/',
        paymentformat: '/backend/paymentinput',
        getLovListValues:
          '/backend/paymentinput/api/paymentformat/listofvalues',
      },
      settings: {
        getProtocol:
          '/backend/settings/api/communication-channel/setting/protocols',
        channelSetting: '/backend/settings/api/communication-channel',
        testConnectionSwift:
          '/backend/settings/api/communication-channel/test-connection',
        testConnectionERP:
          '/backend/settings/api/communication-channel/test-sftp-connection',
        outboundConnector:
          '/backend/settings/api/communication-channel/outbound-connectors',
        approvePaymentFile: '/backend/settings/api/communication-channel/send',
        updateResource:
          '/backend/settings/api/communication-channel/update-resource',
        deleteResource:
          '/backend/settings/api/communication-channel/delete-resource',
        receiveFile: '/backend/settings/api/communication-channel/receive',
        sendFile: `/backend/settings/api/communication-channel/send`,
        statusChange: `/backend/paymentinput/api/paymentformat/payment/file/status`,
        ruleEngineParametersLov: `/backend/ruleengine/api/ruleengine/listofvalues/value?lovName=%s`,
        ruleEngineLov: `/backend/ruleengine/api/ruleengine/listofvalues/value`,
        cashflowRuleEngine: `/backend/ruleengine/api/ruleengine/rules/byBusinessObject/Cashflow`,
        paymentFormatRuleEngine: `/backend/ruleengine/api/ruleengine/rules/byBusinessObject/PaymentFormat`,
        ruleEngine: `/backend/ruleengine/api/ruleengine/rules`,
        deleteRules: `/backend/ruleengine/api/ruleengine/rules/batch`,
        certificates:
          '/backend/settings/api/communication-channel/certificates',
        intiUser: '/backend/settings/api/communication-channel/inituser',
        certificateTypes:
          '/backend/settings/api/communication-channel/listofvalues/value?lovName=CertificateType',
        certificateStatus:
          '/backend/settings/api/communication-channel/setting/certificate-status',
        getAdaptor:
          '/backend/settings/api/communication-channel/listofvalues/value?lovName=CommunicationAdapterType',
        signatureClass:
          '/backend/settings/api/communication-channel/listofvalues/value?lovName=SignatureClass',
        certificateMode:
          '/backend/settings/api/communication-channel/listofvalues/value?lovName=CertificateMode',
        encodingTypes:
          '/backend/settings/api/communication-channel/listofvalues/value?lovName=FileEncodingType',
        keyTypes:
          '/backend/settings/api/communication-channel/listofvalues/value?lovName=KeyType',
        masterdataTypes:
          '/backend/settings/api/communication-channel/listofvalues/value?lovName=CollectionMasterDataEntities',
        userStatus:
          '/backend/settings/api/communication-channel/listofvalues/value?lovName=UserStatus',
        connectorTypes:
          '/backend/settings/api/communication-channel/listofvalues/value?lovName=AccountingDocumentConnectorType',
        FileDelimiterLOV:
          '/backend/settings/api/communication-channel/listofvalues/value',
        bankKeys: '/backend/settings/api/communication-channel/ccID/bank-keys',
        userManagement: '/backend/usermanager/api/v1/users',
        configPump: '/backend/configuration-pump/api/v1/packages',
        packageEntities: '/backend/configuration-pump/api/v1/packageEntityIds',
        finalizePackage: '/backend/configuration-pump/api/v1/finalizations',
        applyPackage: '/backend/configuration-pump/api/v1/distribution',
        downloadPackage: '/backend/configuration-pump/api/v1/downloads',
        userManagementV2: '/backend/usermanager/api/v2/users',
        changePasswordPing:
          '/backend/usermanager/api/v2/users/changePasswordPing',
        updateName: '/backend/usermanager/api/v2/users/updateName',
        getUserDetailPage:
          '/backend/usermanager/api/v2/users/getUserDetailPage',
        allowedUserTypes: '/backend/usermanager/api/v2/users/allowedUserTypes',
        switchTenant: '/backend/usermanager/api/v1/users/switchTenant',
        resentRegistrationLink:
          '/backend/usermanager/api/v1/registration/resendRegistrationLink',
        downloadFiles: '/backend/settings/api/communication-channel/iniLetter',
        deleteUser: '/backend/settings/api/communication-channel/',
        getUserCertificates:
          '/backend/settings/api/communication-channel/certificates',
        getAllExistingCertificates:
          '/backend/settings/api/communication-channel/certificates/names',
        signCertificate:
          '/backend/settings/api/communication-channel/ccID/sign',
        getEbicsSubscribers:
          '/backend/settings/api/communication-channel/ebics-subscribers',
        validateCertificate:
          '/backend/settings/api/communication-channel/ccID/certificates',
        lifeCyclelogs: '/backend/settings/api/monitoring/entities',
        outBoundList:
          '/backend/settings/api/communication-channel/outbound-connector-config',
        jobScheduler: {
          jobDefinition: '/backend/batch/api/v1/jobdefinition',
          jobDefinitionV2: '/backend/batch/api/v2/jobdefinition',
          stepDefinition: '/backend/batch/api/v1/stepdefinition',
          customFunctionTypes: '/backend/batch/api/v1/customTypes',
        },
        featureManagement: {
          features: '/backend/featuremanagement/api/v1/features/',
          modules: '/backend/featuremanagement/api/v1/modules/',
          subscriptions: '/backend/featuremanagement/api/v1/subscriptions/',
          subscriptionCounters:
            '/backend/featuremanagement/api/v1/subscriptionCounters',
          volumeEndpoints: '/backend/featuremanagement/api/v1/volumeEndpoints',
          products:
            '/backend/featuremanagement/api/v1/featurePolicies/products',
          tenantVolumeAttributes:
            '/backend/featuremanagement/api/volumeParameter',
        },
        operatorManagement: '/backend/tenant/api/v1/operators',
        tenantManagement: '/backend/tenant/api/v1/customers',
        assignedTenants: '/backend/tenant/api/v1/tenant',
        enhancementRules:
          '/backend/ruleengine/api/ruleengine/rules/byBusinessObject/PaymentItem',
        enhancementRulesDetails: '/backend/ruleengine/api/ruleengine/rules',
        enhancementRulesStatus:
          '/backend/ruleengine/api/ruleengine/rules/status',
        migrationInfo: {
          migrationConnector:
            '/backend/settings/api/communication-channel/settings/by-info-category/MIGRATION',
          migrationEntity:
            '/backend/masterdata/api/masterdata/import-export/jobs/by-type/MIGRATION',
          migrationSummaryLog:
            '/backend/masterdata/api/masterdata/import/summaryLogs/by-job-id',
          migrationSummaryLogbyProcessId:
            '/backend/masterdata/api/masterdata/import/summaryLogs',
          migrationStart:
            '/backend/masterdata/api/masterdata/migration/start-migration',
          migrationEntityFileDownload:
            '/backend/masterdata/api/masterdata/migration/download-file',
          migrationEntityRecordLog:
            '/backend/masterdata/api/masterdata/import/recordLogs',
          migrationProcessLog: '/backend/masterdata/api/monitoring/processes',
          migrationEntityFileUpload:
            '/backend/masterdata/api/masterdata/migration/upload-file',
          migrationStop: '/backend/masterdata/api/masterdata/migration/stop',
          retryMigration: '/backend/masterdata/api/masterdata/migration/retry-migration',
          migrationStatus: '/backend/masterdata/migration/status',
        },
        import: '/backend/settings/api/communicationchannel/import',
      },
      btfMapping: {
        ServiceName:
          '/backend/settings/api/communication-channel/listofvalues/value?lovName=ServiceName',
        ServiceOptionConcatenation:
          '/backend/settings/api/communication-channel/listofvalues/value?lovName=ServiceOptionConcatenation',
        ContainerAttributes:
          '/backend/settings/api/communication-channel/listofvalues/value?lovName=ContainerAttributes',
        MessageName:
          '/backend/settings/api/communication-channel/listofvalues/value?lovName=MessageName',
        BTFOrderType:
          '/backend/settings/api/communication-channel/listofvalues/value?lovName=BTFOrderType',
        Scope:
          '/backend/settings/api/communication-channel/listofvalues/value?lovName=Scope',
        ccNames:
          '/backend/settings/api/communication-channel/names?adapterType=EBICS',
        getErpConnectorNames:
          '/backend/settings/api/communication-channel?adapterType=ERP',
        getBTFMappings: '/backend/btfMapping/api/btfmappings',
        btfdescription: '/backend/btfMapping/api/btfmappings/btfdescription',
        import: '/backend/btfMapping/api/btfmappings/import',
        messagePriority:
          '/backend/settings/api/communication-channel/listofvalues/value?lovName=MessagePriority',
      },
      quickPay: {
        getPaymentHistory:
          '/backend/paymentinput/api/paymentformat/payment/history',
      },
      audit: {
        getAllAudit: '/backend/audit/api/audit-service/audit',
        getDetailAudit: '/backend/audit/api/audit-service/audit-entity/search',
      },
      paymentOverview: {
        getPaymentFile:
          '/backend/paymentinput/api/paymentformat/payment/overview/',
        getPaymentItems: `/backend/paymentinput/api/paymentformat/payment/payment-item-overview`,
        fileImport: `/backend/paymentinput/api/paymentformat/payment/fileimport`,
        storeFrontEndSetting: `/backend/paymentinput/api/paymentformat/store-frontend-setting/`,
        updateFileStatus: `/backend/paymentinput/api/paymentformat/payment/file/status`,
        deletePaymentFile: `/backend/paymentinput/api/paymentformat/payment/file/`,
        updatePaymentItemStatus: `/backend/paymentinput/api/paymentformat/payment/`,
        deletePaymentItem: `/backend/paymentinput/api/paymentformat/payment/`,
        getPaymentFormatLov: `/backend/paymentinput/api/paymentformat/listofvalues/value`,
        generatePayment: `/backend/paymentinput/api/paymentformat/payment/generate-payments`,
        paymentMessageWidgets: `/backend/paymentinput/api/paymentformat/payment-message/`,
        paymentWidgetFilter: `/backend/paymentinput/api/paymentformat/filter`,
        paymentItemLifecycle: `/backend/paymentinput/api/paymentformat/lifecycle-logs/payment-item`,
        paymentMessageLifecycle: `/backend/paymentinput/api/paymentformat/lifecycle-logs`,
        paymentMessageDummyLifecycle: `/backend/paymentinput/api/paymentformat/lifecycle-logs/payment-message`,
        changeExecutionDate: `/backend/paymentinput/api/paymentformat/payment/file/execution-date`,
        changePaymentExecutionDate: `/backend/paymentinput/api/paymentformat/payment/item/execution-date`,
        paymentMessageRegeneratePayment: `/backend/paymentinput/api/paymentformat/payment/file/regenerate-payment`,
        deletePaymentItemsFromFile: `/backend/paymentinput/api/paymentformat/payment/file/delete-item`,
        removePaymentItemsFromFile: `/backend/paymentinput/api/paymentformat/payment/file/remove-item`,
        acceptDuplicateItem: `/backend/paymentinput/api/paymentformat/payment-item/accept-duplicate`,
        bulkAcceptDuplicateItem: `/backend/paymentinput/api/paymentformat/payment-item/accept-duplicates`,
        getObjectId: `/backend/paymentinput/api/paymentformat/payment-item`,
        getPaymentItem: `/backend/paymentinput/api/paymentformat/payment/payment-item`,
      },
      bankstatus: {
        getLov: '/backend/bankstatus/api/bankstatus/listofvalues/value',
        statusMatchSequence:
          '/backend/bankstatus/api/bankstatus/status-matching-sequence',
        getBankStatus: '/backend/bankstatus/api/bankstatus/search',
        accountWidgetFilter: '/backend/bankstatus/api/bankstatus/filter',
        manualMatch: '/backend/bankstatus/api/bankstatus',
      },
      objectfactory: {
        getInfoCategory:
          '/backend/objectfactory/api/objectfactory/info-category',
        getPaymentFormats: `/backend/objectfactory/api/objectfactory/info-category/format/`,
        getObjectType:
          '/backend/objectfactory/api/objectfactory/objecttype/pi-duplicate-check-config',
        getObject: '/backend/objectfactory/api/objectfactory/object?',
        entityMappings:
          '/backend/objectfactory/api/objectfactory/entity-mapping',
        attributeLovMapping:
          '/backend/objectfactory/api/objectfactory/entity-mapping/%s/lovmapping',
        attributeLovMappingFileImport:
          '/backend/objectfactory/api/objectfactory/entity-mapping/%s/fileImport',
        deleteAttributeLovMapping:
          '/backend/objectfactory/api/objectfactory/entity-mapping/%s/lovmapping/%s',
        getAllPaymentFormats:
          '/backend/objectfactory/api/objectfactory/format/by-info-categories',
      },
      botService: {
        communication: '/backend/digitalassistant/bot_service',
      },
      notifications: {
        notificationURL:
          '/backend/notifications/api/notification-service/notification',
        getAllNotifications: '/backend/notifications/api/notification-service',
        getServerNotifications: '/topic/group',
      },
      insights: {
        getAllItemsInsights: '/backend/insights/api/insight/payment-item/',
        getAllMessageInsights: '/backend/insights/api/insight/payment-message/',
        updateInsightsPrefix: '/backend/insights',
      },
      accountStatementService: {
        getStatementMessage:
          '/backend/accountStatementService/api/account-statement/messages',
        getAccountStatement:
          '/backend/accountStatementService/api/account-statement/statements',
        fileDownload:
          '/backend/accountStatementService/api/account-statement/files',
        deleteStatment:
          '/backend/accountStatementService/api/account-statement/validation/unknown',
        ignoreAlways:
          '/backend/accountStatementService/api/account-statement/validation/unknown/ignoreAlways',
        ignoreUnregisteredStatement:
          '/backend/accountStatementService/api/account-statement/validation/unregistered/ignore',
        addUnregisteredStatementToRegistered:
          '/backend/accountStatementService/api/account-statement/validation/unregistered/addtoregistered',
        getItems:
          '/backend/accountStatementService/api/account-statement/items',
        getAccounts:
          '/backend/accountStatementService/api/account-statement/accounts',
        ignoreBalanceCheck:
          '/backend/accountStatementService/api/account-statement/validation/balancecheck/ignore',
        ignoreItemTally:
          '/backend/accountStatementService/api/account-statement/validation/itemtally/ignore',
        addToAccount:
          '/backend/accountStatementService/api/account-statement/statements/validate',
        getStatementItems:
          '/backend/accountStatementService/api/account-statement/items',
        getLov:
          '/backend/accountStatementService/api/account-statement/listofvalues/value',
      },
      accountingDocumentService: {
        importFile:
          '/backend/accountingDocumentService/api/accountingdocument/fileimport',
        accountingDocumentFilesList:
          '/backend/accountingDocumentService/api/accountingdocument/filemetadata',
        okCriteria: {
          saveMapping:
            '/backend/adchcollection/api/creditcollection/okcriteria/save',
          getMappingsByEntityType:
            '/backend/adchcollection/api/creditcollection/okcriteria/entitytype',
          deleteMapping:
            '/backend/adchcollection/api/creditcollection/okcriteria/%s/attribute/%s',
        },
        aiDecisionEngineConfiguration: {
          getDistributionCriteria:
            '/backend/adchcollection/api/creditcollection/decisionengineconf/distributioncriteria',
          saveDistributionCriteria:
            '/backend/adchcollection/api/creditcollection/decisionengineconf',
        },
        getLov:
          '/backend/accountingDocumentService/api/accountingdocument/listofvalues/value',
        getLovListValues:
          '/backend/accountingDocumentService/api/accountingdocument/listofvalues',
        fileImport:
          '/backend/accountingDocumentService/api/accountingdocument/listofvalues/fileimport',
      },
      certificateManagement: {
        certificateURL: 'backend/certificateManagement/',
      },
      policyService: {
        policyUrl: '/backend/policy/api/v1/authorization',
      },
      approvalWorkflowService: {
        authLogin: '/backend/paymentinput/api/paymentformat/auth-token',
        postingControl:
          '/backend/approvalWorkflowService/api/configuration/posting_control',
        codingDefault:
          '/backend/approvalWorkflowService/api/master-data/coding_defaults',
        getFieldsForEntities:
          '/backend/approvalWorkflowService/api/configuration/field-configs/',
        getUsers: '/backend/approvalWorkflowService/auth/api/admin/users',
        getRecordTypes:
          '/backend/approvalWorkflowService/api/configuration/conditions',
        createGroup: '/backend/approvalWorkflowService/auth/api/admin/users',
        myActionList: '/backend/approvalWorkflowService/api/pts',
        lockApprovalRecord: '/backend/approvalWorkflowService/api/pts/%s/lock',
        ApprovalRecord:
          '/backend/approvalWorkflowService/api/pts/wfaction/%s/%s',
        unLockApprovalRecord:
          '/backend/approvalWorkflowService/api/pts/%s/unlock',
        updateNotes: '/backend/approvalWorkflowService/api/pts/%s/notes',
        updateUserGroup:
          '/backend/approvalWorkflowService/auth/api/admin/users',
      },
      approvalWorkflowServiceNew:{
        postingControl:
        '/backend/paymentinput/api/paymentformat/configuration/posting_control',
      codingDefault:
        '/backend/paymentinput/api/paymentformat/master-data/coding_defaults',
      getFieldsForEntities:
        '/backend/paymentinput/api/paymentformat/configuration/field-configs/',
      getUsers: '/backend/paymentinput/api/paymentformat/api/admin/users',
      getRecordTypes:'/backend/paymentinput/api/paymentformat/configuration/conditions',
      createGroup: '/backend/paymentinput/api/paymentformat/api/admin/users',
      myActionList: '/backend/paymentinput/api/paymentformat/pts',
      lockApprovalRecord: '/backend/approvalWorkflowService/api/pts/%s/lock',
      ApprovalRecord:
        '/backend/approvalWorkflowService/api/pts/wfaction/%s/%s',
      unLockApprovalRecord:
        '/backend/approvalWorkflowService/api/pts/%s/unlock',
      updateNotes: '/backend/approvalWorkflowService/api/pts/%s/notes',
      updateUserGroup:
        '/backend/paymentinput/api/paymentformat/api/admin/users',
      },
      policy: {
        url: '/backend/policy/api/v1',
        roles: '/backend/policy/api/v1/Role',
        products: '/backend/policy/api/v1/product',
        productNames: '/backend/policy/api/v1/product/names',
        applications: '/backend/policy/api/v1/application',
        getProductName: '/backend/policy/api/v1/product',
        roleTypes: '/backend/policy/api/v1/Role/types',
        roleUsageByUsers: '/backend/usermanager/api/v1/users/getRoleUsage',
        menuPermissions: '/backend/policy/api/v1/authorization/applications',
        applicationPermissions: '/backend/policy/api/v1/application/',
        roleAttributes: '/backend/policy/api/v1/permissionAttribute/',
        roleTypeDependentProducts: '/backend/policy/api/v2/product',
        roleTypeDependentApplications: '/backend/policy/api/v2/application',
        roleTypeDependentPermissionActivities:
          '/backend/policy/api/v2/permissionActivity',
      },
      frontEndSettings: '/backend/frontendsettings/api/v1/settings',
      getAllTenantUrl: '/backend/tenant/api/v1/tenant/selectAll',
      registrationConfirm: '/backend/usermanager/api/v1/registration',
      gdpr: {
        websocket: '/backend/gdprpersonaldata/ws',
      },
      recipe: {
        getRecipe: '/backend/adpservice/api/adpservice/recipe',
      },
      areStatus: {
        statusLov: '/backend/adpservice/api/adpservice/listofvalues/',
      },
      invitationConfirm:
        '/backend/usermanager/api/v1/registration/invitationConfirm',
      experienceBuilder: {
        getDefaultView:
          '/backend/experiencebuilder/api/experiencebuilder/customviews/defaultview',
        saveCustomView:
          '/backend/experiencebuilder/api/experiencebuilder/customviews',
      },
      postman: {
        getPaymentFormatLov: `/backend/paymentinput/api/paymentformat/listofvalues/value`,
      },
      tenant: {
        cdnToken: '/backend/tenant/api/v1/tenant/picture/token'
      }
    },
  };
  //url's which doesn't require TENAT ID //
  static withoutTenantIdUrls = [
    ApiConstant.constants.API_ENDPOINTS.settings.certificateStatus,
    ApiConstant.constants.API_ENDPOINTS.settings.getProtocol,
    ApiConstant.constants.API_ENDPOINTS.botService.communication,
    ApiConstant.constants.API_ENDPOINTS.policyService.policyUrl,
    ApiConstant.constants.API_ENDPOINTS.settings.certificateStatus,
    ApiConstant.constants.API_ENDPOINTS.settings.getProtocol,
    ApiConstant.constants.API_ENDPOINTS.botService.communication,
    ApiConstant.constants.API_ENDPOINTS.frontEndSettings,
    ApiConstant.constants.API_ENDPOINTS.settings.userManagement,
    ApiConstant.constants.API_ENDPOINTS.settings.configPump,
    ApiConstant.constants.API_ENDPOINTS.settings.packageEntities,
    ApiConstant.constants.API_ENDPOINTS.settings.finalizePackage,
    ApiConstant.constants.API_ENDPOINTS.settings.applyPackage,
    ApiConstant.constants.API_ENDPOINTS.settings.downloadPackage,
    ApiConstant.constants.API_ENDPOINTS.settings.userManagementV2,
    ApiConstant.constants.API_ENDPOINTS.settings.changePasswordPing,
    ApiConstant.constants.API_ENDPOINTS.settings.allowedUserTypes,
    ApiConstant.constants.API_ENDPOINTS.settings.updateName,
    ApiConstant.constants.API_ENDPOINTS.settings.getUserDetailPage,
    ApiConstant.constants.API_ENDPOINTS.settings.resentRegistrationLink,
    ApiConstant.constants.API_ENDPOINTS.policy.roles,
    ApiConstant.constants.API_ENDPOINTS.policy.roleTypes,
    ApiConstant.constants.API_ENDPOINTS.getAllTenantUrl,
    ApiConstant.constants.API_ENDPOINTS.settings.switchTenant,
    ApiConstant.constants.API_ENDPOINTS.registrationConfirm,
    ApiConstant.constants.API_ENDPOINTS.settings.featureManagement.features,
    ApiConstant.constants.API_ENDPOINTS.settings.featureManagement.modules,
    ApiConstant.constants.API_ENDPOINTS.settings.featureManagement
      .subscriptions,
    ApiConstant.constants.API_ENDPOINTS.settings.featureManagement
      .subscriptionCounters,
    ApiConstant.constants.API_ENDPOINTS.settings.featureManagement
      .volumeEndpoints,
    ApiConstant.constants.API_ENDPOINTS.settings.featureManagement
      .tenantVolumeAttributes,
    ApiConstant.constants.API_ENDPOINTS.settings.operatorManagement,
    ApiConstant.constants.API_ENDPOINTS.settings.tenantManagement,
    ApiConstant.constants.API_ENDPOINTS.settings.assignedTenants,
    ApiConstant.constants.API_ENDPOINTS.policy.productNames,
    ApiConstant.constants.API_ENDPOINTS.policy.applicationPermissions,
    ApiConstant.constants.API_ENDPOINTS.invitationConfirm,
    ApiConstant.constants.API_ENDPOINTS.tenant.cdnToken,
  ];
  static jobSchedulerUrls = [
    '/backend/batch/api/v1/jobdefinition',
    '/backend/batch/api/v2/jobdefinition',
    '/backend/batch/api/v1/stepdefinition',
  ];
}
