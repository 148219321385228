import { environment } from '../../../environments/environment';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ROUTER_LINKS_FULL_PATH: any = {};

/** Login Module */
ROUTER_LINKS_FULL_PATH['login'] = '/login';

ROUTER_LINKS_FULL_PATH['alevate'] = environment.routeUrlConfig;
ROUTER_LINKS_FULL_PATH['home'] = ROUTER_LINKS_FULL_PATH['alevate'] + '/home';
ROUTER_LINKS_FULL_PATH['paymentapp'] =
  ROUTER_LINKS_FULL_PATH['alevate'] + '/payment-app';
ROUTER_LINKS_FULL_PATH['collectionapp'] =
  ROUTER_LINKS_FULL_PATH['alevate'] + '/collections-ui';
ROUTER_LINKS_FULL_PATH['cashmanagementapp'] =
  ROUTER_LINKS_FULL_PATH['alevate'] + '/cashmanagement-app';
ROUTER_LINKS_FULL_PATH['ruleengine'] =
  ROUTER_LINKS_FULL_PATH['alevate'] + '/alevate-rule-engine-app';

/** Dashboard Module */
ROUTER_LINKS_FULL_PATH['dashboard'] =
  ROUTER_LINKS_FULL_PATH['alevate'] + '/dashboard';
ROUTER_LINKS_FULL_PATH['dashboard1'] =
  ROUTER_LINKS_FULL_PATH['dashboard'] + '/dashboard1';
ROUTER_LINKS_FULL_PATH['dashboard2'] =
  ROUTER_LINKS_FULL_PATH['dashboard'] + '/dashboard2';
ROUTER_LINKS_FULL_PATH['dashboardSummary'] =
  ROUTER_LINKS_FULL_PATH['dashboard'] + '/summary';
ROUTER_LINKS_FULL_PATH['collectionsDashboard'] =
  ROUTER_LINKS_FULL_PATH['collectionapp'] + '/dashboard/dashboard1';
ROUTER_LINKS_FULL_PATH['collectionsDashboard2'] =
  ROUTER_LINKS_FULL_PATH['collectionapp'] + '/dashboard/dashboard2';
ROUTER_LINKS_FULL_PATH['cashManagementDashboard'] =
  ROUTER_LINKS_FULL_PATH['cashmanagementapp'] + '/dashboard';

/** Payments Module */
ROUTER_LINKS_FULL_PATH['payments'] =
  ROUTER_LINKS_FULL_PATH['paymentapp'] + '/payments';
ROUTER_LINKS_FULL_PATH['paymentsInput'] =
  ROUTER_LINKS_FULL_PATH['payments'] + '/payment-input';
ROUTER_LINKS_FULL_PATH['paymentFiles'] =
  ROUTER_LINKS_FULL_PATH['payments'] + '/payment-messages';
ROUTER_LINKS_FULL_PATH['paymentFileDetails'] =
  ROUTER_LINKS_FULL_PATH['paymentFiles'] + '/%s';
ROUTER_LINKS_FULL_PATH['paymentFileDetailsItems'] =
  ROUTER_LINKS_FULL_PATH['paymentFileDetails'] + '/payment-items';
ROUTER_LINKS_FULL_PATH['paymentFileDetailsItemsQuickPay'] =
  ROUTER_LINKS_FULL_PATH['paymentFileDetailsItems'] + '/quick-pay';
ROUTER_LINKS_FULL_PATH['paymentFileDetailsItemsManualInput'] =
  ROUTER_LINKS_FULL_PATH['paymentFileDetails'] + '/manual-input';
ROUTER_LINKS_FULL_PATH['paymentFileDetailsItemsDetails'] =
  ROUTER_LINKS_FULL_PATH['paymentFileDetailsItems'] + '/%s';

ROUTER_LINKS_FULL_PATH['paymentItems'] =
  ROUTER_LINKS_FULL_PATH['payments'] + '/payment-items';
ROUTER_LINKS_FULL_PATH['paymentItemDetails'] =
  ROUTER_LINKS_FULL_PATH['paymentItems'] + '/%s';
ROUTER_LINKS_FULL_PATH['paymentItemsQuickPay'] =
  ROUTER_LINKS_FULL_PATH['paymentItems'] + '/quick-pay';
ROUTER_LINKS_FULL_PATH['paymentItemsManualInput'] =
  ROUTER_LINKS_FULL_PATH['paymentItems'] + '/manual-input';
ROUTER_LINKS_FULL_PATH['paymentManualInput'] =
  ROUTER_LINKS_FULL_PATH['payments'] + '/manual-input';
ROUTER_LINKS_FULL_PATH['bankStatements'] =
  ROUTER_LINKS_FULL_PATH['payments'] + '/bank-statements';
ROUTER_LINKS_FULL_PATH['bankStatus'] =
  ROUTER_LINKS_FULL_PATH['payments'] + '/bank-status';
ROUTER_LINKS_FULL_PATH['bankStatusDetails'] =
  ROUTER_LINKS_FULL_PATH['bankStatus'] + '/%s';
ROUTER_LINKS_FULL_PATH['paymentOthers'] =
  ROUTER_LINKS_FULL_PATH['payments'] + '/others';
ROUTER_LINKS_FULL_PATH['directDebitMessages'] =
  ROUTER_LINKS_FULL_PATH['payments'] + '/direct-debit-messages';
ROUTER_LINKS_FULL_PATH['directDebitItems'] =
  ROUTER_LINKS_FULL_PATH['payments'] + '/direct-debit-items';
ROUTER_LINKS_FULL_PATH['directDebitItemsManualInput'] =
  ROUTER_LINKS_FULL_PATH['payments'] + '/manual-input-direct-debit';

ROUTER_LINKS_FULL_PATH['cashManagement'] =
  ROUTER_LINKS_FULL_PATH['alevate'] + '/cashmanagement-app';
ROUTER_LINKS_FULL_PATH['unprocessedCashflows'] =
  ROUTER_LINKS_FULL_PATH['cashManagement'] + '/unprocessed-cashflows';
ROUTER_LINKS_FULL_PATH['cashflowFiles'] =
  ROUTER_LINKS_FULL_PATH['cashManagement'] + '/cashflow-files';
ROUTER_LINKS_FULL_PATH['cashflows'] =
  ROUTER_LINKS_FULL_PATH['cashManagement'] + '/cashflows';
ROUTER_LINKS_FULL_PATH['cashPositionWorksheet'] =
  ROUTER_LINKS_FULL_PATH['cashManagement'] + '/cash-position-worksheet';
ROUTER_LINKS_FULL_PATH['creditLines'] =
  ROUTER_LINKS_FULL_PATH['cashManagement'] + '/credit-lines';

ROUTER_LINKS_FULL_PATH['cashApp'] = 'alevate/cashapp';
ROUTER_LINKS_FULL_PATH['cashAllocation'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/cash-allocation/bank-statement';
ROUTER_LINKS_FULL_PATH['cashAppFileTracker'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/monitoring/file-tracker';
ROUTER_LINKS_FULL_PATH['cashAppContentTracker'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/monitoring/content-tracker';
ROUTER_LINKS_FULL_PATH['cashAppProcessingReport'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/monitoring/processing-report';
ROUTER_LINKS_FULL_PATH['cashAppPayments'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/transactional/isas';
ROUTER_LINKS_FULL_PATH['cashAppOpenItems'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/transactional/open-item-core-data';

ROUTER_LINKS_FULL_PATH['accountingSystems'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/settings/accounting-systems';
ROUTER_LINKS_FULL_PATH['origins'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/settings/origin-core-data';
ROUTER_LINKS_FULL_PATH['toleranceGroups'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/settings/tolerance-group-core-data';
ROUTER_LINKS_FULL_PATH['reasonCodes'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/settings/internal-reason-codes';
ROUTER_LINKS_FULL_PATH['paymentTerms'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/settings/payment-condition';
ROUTER_LINKS_FULL_PATH['dataPurgeOpenItems'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/settings/open-item-import-settings';

  ROUTER_LINKS_FULL_PATH['rulebookFilters'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/rulebook/fine-filter';
ROUTER_LINKS_FULL_PATH['postingLines'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/rulebook/posting-rule';
ROUTER_LINKS_FULL_PATH['postingRules'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/rulebook/profile-manager';
ROUTER_LINKS_FULL_PATH['postingRulesSequences'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/rulebook/profile-sequence';
ROUTER_LINKS_FULL_PATH['businessPartnerEnrichment'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/transactional/business-partner-core-data';
ROUTER_LINKS_FULL_PATH['filterValueConversion'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/transactional/replacement-data';
ROUTER_LINKS_FULL_PATH['conditions'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/rulebook/conditions';
ROUTER_LINKS_FULL_PATH['setRestrictions'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/rulebook/restrictions';

ROUTER_LINKS_FULL_PATH['accountsPayable'] = 'accounts-payable';
ROUTER_LINKS_FULL_PATH['accountPayableInvoiceMonitor'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/invoice-monitor';
ROUTER_LINKS_FULL_PATH['accountPayableInvoiceAllocation'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/invoice-allocation';
ROUTER_LINKS_FULL_PATH['accountPayablePosting'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/payable-posting';
ROUTER_LINKS_FULL_PATH['accountPayableApprovals'] =
  ROUTER_LINKS_FULL_PATH['cashApp'] + '/approvals';

ROUTER_LINKS_FULL_PATH['accounts'] =
  ROUTER_LINKS_FULL_PATH['paymentapp'] + '/accounts';
ROUTER_LINKS_FULL_PATH['accountStatementMessages'] =
  ROUTER_LINKS_FULL_PATH['accounts'] + '/account-statment-messages';
ROUTER_LINKS_FULL_PATH['accountStatementMessagesDetails'] =
  ROUTER_LINKS_FULL_PATH['accountStatementMessages'] + '/%s';
ROUTER_LINKS_FULL_PATH['accountStatementMessageStatements'] =
  ROUTER_LINKS_FULL_PATH['accountStatementMessagesDetails'] +
  '/account-statements';
ROUTER_LINKS_FULL_PATH['accountStatementMessageAddtoAcount'] =
  ROUTER_LINKS_FULL_PATH['accountStatementMessageStatements'] +
  '/add-to-account';
ROUTER_LINKS_FULL_PATH['accountStatementMessagesItemDetails'] =
  ROUTER_LINKS_FULL_PATH['accountStatementMessageStatements'] + '/%s';
ROUTER_LINKS_FULL_PATH['accountStatementMessageStatementItems'] =
  ROUTER_LINKS_FULL_PATH['accountStatementMessagesItemDetails'] +
  '/statement-items';

ROUTER_LINKS_FULL_PATH['accountStatements'] =
  ROUTER_LINKS_FULL_PATH['accounts'] + '/account-statements';
ROUTER_LINKS_FULL_PATH['accountStatementsAddtoAcount'] =
  ROUTER_LINKS_FULL_PATH['accountStatements'] + '/add-to-account';
ROUTER_LINKS_FULL_PATH['accountStatementItemDetails'] =
  ROUTER_LINKS_FULL_PATH['accountStatements'] + '/%s';
ROUTER_LINKS_FULL_PATH['accountStatementItems'] =
  ROUTER_LINKS_FULL_PATH['accountStatementItemDetails'] + '/statement-items';

ROUTER_LINKS_FULL_PATH['statementItems'] =
  ROUTER_LINKS_FULL_PATH['accounts'] + '/statement-items';
ROUTER_LINKS_FULL_PATH['controlCenter'] =
  ROUTER_LINKS_FULL_PATH['accounts'] + '/control-center';
//IntraDay Messages
ROUTER_LINKS_FULL_PATH['intraDayMessages'] =
  ROUTER_LINKS_FULL_PATH['accounts'] + '/intraday-messages';
ROUTER_LINKS_FULL_PATH['intraDayMessagesDetails'] =
  ROUTER_LINKS_FULL_PATH['intraDayMessages'] + '/%s';
ROUTER_LINKS_FULL_PATH['intraDayMessageStatementItems'] =
  ROUTER_LINKS_FULL_PATH['intraDayMessagesDetails'] + '/intraday-statements';
//IntraDay Items
ROUTER_LINKS_FULL_PATH['intraDayStatements'] =
  ROUTER_LINKS_FULL_PATH['accounts'] + '/intraday-statements';
ROUTER_LINKS_FULL_PATH['intraDayStatementDetails'] =
  ROUTER_LINKS_FULL_PATH['intraDayStatement'] + '/%s';

/** MasterData Module */
ROUTER_LINKS_FULL_PATH['masterdata'] =
  ROUTER_LINKS_FULL_PATH['alevate'] + '/masterdata';
ROUTER_LINKS_FULL_PATH['externalServiceProvider'] =
  ROUTER_LINKS_FULL_PATH['masterdata'] + '/collection-agency';
ROUTER_LINKS_FULL_PATH['addExternalServiceProvider'] =
  ROUTER_LINKS_FULL_PATH['externalServiceProvider'] + '/add';
ROUTER_LINKS_FULL_PATH['editExternalServiceProvider'] =
  ROUTER_LINKS_FULL_PATH['externalServiceProvider'] + '/edit/%s';
ROUTER_LINKS_FULL_PATH['externalServiceProviderName'] =
  ROUTER_LINKS_FULL_PATH['externalServiceProvider'] + '/%s';
ROUTER_LINKS_FULL_PATH['externalServiceProviderDetails'] =
  ROUTER_LINKS_FULL_PATH['externalServiceProviderName'] + '/overview';

ROUTER_LINKS_FULL_PATH['customer'] =
  ROUTER_LINKS_FULL_PATH['masterdata'] + '/customer';
ROUTER_LINKS_FULL_PATH['customerManageMasterData'] =
  ROUTER_LINKS_FULL_PATH['customer'] + '/manage';
ROUTER_LINKS_FULL_PATH['customerUpdateMasterData'] =
  ROUTER_LINKS_FULL_PATH['customerManageMasterData'] + '/%s';
ROUTER_LINKS_FULL_PATH['customerDetailsMasterData'] =
  ROUTER_LINKS_FULL_PATH['customerUpdateMasterData'] + '/overview';

ROUTER_LINKS_FULL_PATH['paymentPartner'] =
  ROUTER_LINKS_FULL_PATH['masterdata'] + '/payment-partner';
ROUTER_LINKS_FULL_PATH['paymentPartnerManageMasterData'] =
  ROUTER_LINKS_FULL_PATH['paymentPartner'] + '/manage';
ROUTER_LINKS_FULL_PATH['paymentPartnerUpdateMasterData'] =
  ROUTER_LINKS_FULL_PATH['paymentPartnerManageMasterData'] + '/%s';
ROUTER_LINKS_FULL_PATH['paymentPartnerDetailsMasterData'] =
  ROUTER_LINKS_FULL_PATH['paymentPartnerUpdateMasterData'] + '/overview';

ROUTER_LINKS_FULL_PATH['bank'] = ROUTER_LINKS_FULL_PATH['masterdata'] + '/bank';
ROUTER_LINKS_FULL_PATH['bankManageMasterData'] =
  ROUTER_LINKS_FULL_PATH['bank'] + '/manage';
ROUTER_LINKS_FULL_PATH['bankUpdateMasterData'] =
  ROUTER_LINKS_FULL_PATH['bankManageMasterData'] + '/%s';
ROUTER_LINKS_FULL_PATH['bankDetailsMasterData'] =
  ROUTER_LINKS_FULL_PATH['bankUpdateMasterData'] + '/overview';

ROUTER_LINKS_FULL_PATH['externalSystem'] =
  ROUTER_LINKS_FULL_PATH['masterdata'] + '/externalSystem';
ROUTER_LINKS_FULL_PATH['externalSystemManageMasterData'] =
  ROUTER_LINKS_FULL_PATH['externalSystem'] + '/manage';
ROUTER_LINKS_FULL_PATH['externalSystemUpdateMasterData'] =
  ROUTER_LINKS_FULL_PATH['externalSystemManageMasterData'] + '/%s';

ROUTER_LINKS_FULL_PATH['countryMasterData'] =
  ROUTER_LINKS_FULL_PATH['masterdata'] + '/country';
ROUTER_LINKS_FULL_PATH['currencyMasterData'] =
  ROUTER_LINKS_FULL_PATH['masterdata'] + '/currency';
ROUTER_LINKS_FULL_PATH['accountMasterData'] =
  ROUTER_LINKS_FULL_PATH['masterdata'] + '/account';
ROUTER_LINKS_FULL_PATH['accountManageMasterData'] =
  ROUTER_LINKS_FULL_PATH['accountMasterData'] + '/manage';
ROUTER_LINKS_FULL_PATH['accountUpdateMasterData'] =
  ROUTER_LINKS_FULL_PATH['accountManageMasterData'] + '/%s';
ROUTER_LINKS_FULL_PATH['accountDetailsMasterData'] =
  ROUTER_LINKS_FULL_PATH['accountUpdateMasterData'] + '/overview';
ROUTER_LINKS_FULL_PATH['cashflowtypeMasterData'] =
  ROUTER_LINKS_FULL_PATH['masterdata'] + '/cashflow-type';
ROUTER_LINKS_FULL_PATH['listOfValues'] =
  ROUTER_LINKS_FULL_PATH['masterdata'] + '/list-of-values';
ROUTER_LINKS_FULL_PATH['fxRatesMasterData'] =
  ROUTER_LINKS_FULL_PATH['masterdata'] + '/fx-rates';
ROUTER_LINKS_FULL_PATH['fxRatesManageMasterData'] =
  ROUTER_LINKS_FULL_PATH['fxRatesMasterData'] + '/manage';
ROUTER_LINKS_FULL_PATH['fxRatesUpdateMasterData'] =
  ROUTER_LINKS_FULL_PATH['fxRatesManageMasterData'] + '/edit';
ROUTER_LINKS_FULL_PATH['fxRatesDetailsMasterData'] =
  ROUTER_LINKS_FULL_PATH['fxRatesManageMasterData'] + '/overview';
ROUTER_LINKS_FULL_PATH['masterBankMasterData'] =
  ROUTER_LINKS_FULL_PATH['masterdata'] + '/master-bank';
ROUTER_LINKS_FULL_PATH['masterBankManageMasterData'] =
  ROUTER_LINKS_FULL_PATH['masterBankMasterData'] + '/manage';
ROUTER_LINKS_FULL_PATH['masterBankUpdateMasterData'] =
  ROUTER_LINKS_FULL_PATH['masterBankManageMasterData'] + '/%s';
ROUTER_LINKS_FULL_PATH['masterBankDetailsMasterData'] =
  ROUTER_LINKS_FULL_PATH['masterBankUpdateMasterData'] + '/overview';

ROUTER_LINKS_FULL_PATH['companyMasterData'] =
  ROUTER_LINKS_FULL_PATH['masterdata'] + '/company';
ROUTER_LINKS_FULL_PATH['companyManageMasterData'] =
  ROUTER_LINKS_FULL_PATH['companyMasterData'] + '/manage';
ROUTER_LINKS_FULL_PATH['companyUpdateMasterData'] =
  ROUTER_LINKS_FULL_PATH['companyManageMasterData'] + '/%s';
ROUTER_LINKS_FULL_PATH['companyDetailsMasterData'] =
  ROUTER_LINKS_FULL_PATH['companyUpdateMasterData'] + '/overview';

ROUTER_LINKS_FULL_PATH['cflevelsMasterData'] =
  ROUTER_LINKS_FULL_PATH['masterdata'] + '/cf-levels';

ROUTER_LINKS_FULL_PATH['liqudityTypesMasterData'] =
  ROUTER_LINKS_FULL_PATH['masterdata'] + '/liquidity-types';

ROUTER_LINKS_FULL_PATH['collectionsApp'] = 'collections';
ROUTER_LINKS_FULL_PATH['lineItems'] =
  ROUTER_LINKS_FULL_PATH['collectionsApp'] + '/line-items';
ROUTER_LINKS_FULL_PATH['engagementOutbound'] =
  ROUTER_LINKS_FULL_PATH['collectionsApp'] + '/engagement-outbound';
ROUTER_LINKS_FULL_PATH['statusImport'] =
  ROUTER_LINKS_FULL_PATH['collectionsApp'] + '/statusImport';

ROUTER_LINKS_FULL_PATH['postman'] =
  ROUTER_LINKS_FULL_PATH['payments'] + '/postman';

/** collection status */
ROUTER_LINKS_FULL_PATH['collectionStatus'] =
  ROUTER_LINKS_FULL_PATH['masterdata'] + '/collection-status';
ROUTER_LINKS_FULL_PATH['collectionStatusManageMasterData'] =
  ROUTER_LINKS_FULL_PATH['collectionStatus'] + '/manage';
ROUTER_LINKS_FULL_PATH['collectionStatusUpdateMasterData'] =
  ROUTER_LINKS_FULL_PATH['collectionStatusManageMasterData'] + '/%s';

/** Setting Module > Platform app */
ROUTER_LINKS_FULL_PATH['platformapp'] =
  ROUTER_LINKS_FULL_PATH['alevate'] + '/platform-app';
ROUTER_LINKS_FULL_PATH['operatorDashboard'] =
  ROUTER_LINKS_FULL_PATH['platformapp'] + '/operator-dashboard';
ROUTER_LINKS_FULL_PATH['profile'] =
  ROUTER_LINKS_FULL_PATH['platformapp'] + '/profile';
ROUTER_LINKS_FULL_PATH['tenantDetails'] =
  ROUTER_LINKS_FULL_PATH['platformapp'] + '/tenant-details';
ROUTER_LINKS_FULL_PATH['userManagement'] =
  ROUTER_LINKS_FULL_PATH['platformapp'] + '/user-management';
ROUTER_LINKS_FULL_PATH['manageUserManagement'] =
  ROUTER_LINKS_FULL_PATH['userManagement'] + '/manage';
ROUTER_LINKS_FULL_PATH['featureManagement'] =
  ROUTER_LINKS_FULL_PATH['platformapp'] + '/feature-management';
ROUTER_LINKS_FULL_PATH['manageFeaturesFeatureManagement'] =
  ROUTER_LINKS_FULL_PATH['featureManagement'] + '/feature/manage';
ROUTER_LINKS_FULL_PATH['manageModulesFeatureManagement'] =
  ROUTER_LINKS_FULL_PATH['featureManagement'] + '/module/manage';
ROUTER_LINKS_FULL_PATH['manageSubscriptionsFeatureManagement'] =
  ROUTER_LINKS_FULL_PATH['featureManagement'] + '/subscription/manage';
ROUTER_LINKS_FULL_PATH['userAuthorization'] =
  ROUTER_LINKS_FULL_PATH['platformapp'] + '/authorization';
ROUTER_LINKS_FULL_PATH['authorizationUsageInfo'] =
  ROUTER_LINKS_FULL_PATH['userAuthorization'] + '/usage-info';
ROUTER_LINKS_FULL_PATH['manageUserAuthorization'] =
  ROUTER_LINKS_FULL_PATH['userAuthorization'] + '/manage';
ROUTER_LINKS_FULL_PATH['tenantManagement'] =
  ROUTER_LINKS_FULL_PATH['platformapp'] + '/tenant-management';
ROUTER_LINKS_FULL_PATH['manageTenantManagement'] =
  ROUTER_LINKS_FULL_PATH['tenantManagement'] + '/manage';
ROUTER_LINKS_FULL_PATH['operatorManagement'] =
  ROUTER_LINKS_FULL_PATH['platformapp'] + '/operator-management';
ROUTER_LINKS_FULL_PATH['manageOperatorManagement'] =
  ROUTER_LINKS_FULL_PATH['operatorManagement'] + '/manage';
ROUTER_LINKS_FULL_PATH['configurationPackManagement'] =
  ROUTER_LINKS_FULL_PATH['platformapp'] + '/configuration-pack-management';
ROUTER_LINKS_FULL_PATH['manageConfigurationPackManagement'] =
  ROUTER_LINKS_FULL_PATH['configurationPackManagement'] + '/manage';
ROUTER_LINKS_FULL_PATH['approvalWorkflowNew'] =
  ROUTER_LINKS_FULL_PATH['platformapp'] + '/new-approval-workflow';

/**Setting Module */
ROUTER_LINKS_FULL_PATH['settings'] =
  ROUTER_LINKS_FULL_PATH['alevate'] + '/settings';

ROUTER_LINKS_FULL_PATH['statusMessageSettings'] =
  ROUTER_LINKS_FULL_PATH['settings'] + '/status-message-settings';
ROUTER_LINKS_FULL_PATH['manageStatusMessageSettings'] =
  ROUTER_LINKS_FULL_PATH['statusMessageSettings'] + '/manage';
ROUTER_LINKS_FULL_PATH['overviewStatusMessageSettings'] =
  ROUTER_LINKS_FULL_PATH['manageStatusMessageSettings'] + '/%s/overview';
ROUTER_LINKS_FULL_PATH['connectorSettings'] =
  ROUTER_LINKS_FULL_PATH['settings'] + '/connector-settings';
ROUTER_LINKS_FULL_PATH['outBoundConnector'] =
  ROUTER_LINKS_FULL_PATH['settings'] + '/outbound-connector';
ROUTER_LINKS_FULL_PATH['connectorSettingsManage'] =
  ROUTER_LINKS_FULL_PATH['connectorSettings'] + '/manage';
ROUTER_LINKS_FULL_PATH['connectorSettingsUpdate'] =
  ROUTER_LINKS_FULL_PATH['connectorSettingsManage'] + '/%s';
ROUTER_LINKS_FULL_PATH['connectorSettingsDetails'] =
  ROUTER_LINKS_FULL_PATH['connectorSettingsUpdate'] + '/overview';

ROUTER_LINKS_FULL_PATH['duplicateCheckSetup'] =
  ROUTER_LINKS_FULL_PATH['settings'] + '/duplicate-check-setup';
ROUTER_LINKS_FULL_PATH['cashflowTypeAssignment'] =
  ROUTER_LINKS_FULL_PATH['settings'] + '/cashflow-type-assignment';

ROUTER_LINKS_FULL_PATH['approvalWorkflow'] =
  ROUTER_LINKS_FULL_PATH['settings'] + '/approval-workflow';

ROUTER_LINKS_FULL_PATH['manageApprovalWorkflowSetup'] =
  ROUTER_LINKS_FULL_PATH['approvalWorkflow'] + '/manage';

ROUTER_LINKS_FULL_PATH['jobScheduler'] =
  ROUTER_LINKS_FULL_PATH['settings'] + '/job-scheduler';
ROUTER_LINKS_FULL_PATH['jobLogs'] =
  ROUTER_LINKS_FULL_PATH['jobScheduler'] + '/job-logs';
ROUTER_LINKS_FULL_PATH['jobSchedulerManage'] =
  ROUTER_LINKS_FULL_PATH['jobScheduler'] + '/manage';
ROUTER_LINKS_FULL_PATH['jobSchedulerUpdate'] =
  ROUTER_LINKS_FULL_PATH['jobSchedulerManage'] + '/%s';

ROUTER_LINKS_FULL_PATH['externalCollectionsSetup'] =
  ROUTER_LINKS_FULL_PATH['alevate'] + '/externalCollectionsSetup';
ROUTER_LINKS_FULL_PATH['okCriteria'] =
  ROUTER_LINKS_FULL_PATH['externalCollectionsSetup'] + '/directAllocation';
ROUTER_LINKS_FULL_PATH['aiDecisionEngine'] =
  ROUTER_LINKS_FULL_PATH['externalCollectionsSetup'] + '/aiDecisionEngine';

ROUTER_LINKS_FULL_PATH['formatIdendification'] =
  ROUTER_LINKS_FULL_PATH['settings'] + '/payment-format-identification';

ROUTER_LINKS_FULL_PATH['cashflowEnhancements'] =
  ROUTER_LINKS_FULL_PATH['settings'] + '/cashflow-enhancements';

ROUTER_LINKS_FULL_PATH['managecashflowEnhancements'] =
  ROUTER_LINKS_FULL_PATH['cashflowEnhancements'] + '/manage';

ROUTER_LINKS_FULL_PATH['viewcashflowEnhancements'] =
  ROUTER_LINKS_FULL_PATH['cashflowEnhancements'] + '/manage/view';

ROUTER_LINKS_FULL_PATH['migration'] =
  ROUTER_LINKS_FULL_PATH['settings'] + '/migration';
ROUTER_LINKS_FULL_PATH['customerMatchingCriteria'] =
  ROUTER_LINKS_FULL_PATH['settings'] + '/customerMatchingCriteriaConfiguration';

/**Import Logging Module */
ROUTER_LINKS_FULL_PATH['importLogging'] =
  ROUTER_LINKS_FULL_PATH['alevate'] + '/importLogging';
ROUTER_LINKS_FULL_PATH['messageImportOverview'] =
  ROUTER_LINKS_FULL_PATH['importLogging'] + '/messageImportOverview';

/** collection Module */
ROUTER_LINKS_FULL_PATH['collectionsManagement'] =
  ROUTER_LINKS_FULL_PATH['collectionapp'] + '/collections';
ROUTER_LINKS_FULL_PATH['accountingDocumentMessages'] =
  ROUTER_LINKS_FULL_PATH['collectionsManagement'] +
  '/accountingDocumentMessages';
ROUTER_LINKS_FULL_PATH['manageAccountingDocumentMessages'] =
  ROUTER_LINKS_FULL_PATH['accountingDocumentMessages'] + '/%s';
ROUTER_LINKS_FULL_PATH['accountingDocumentMessagesDetails'] =
  ROUTER_LINKS_FULL_PATH['manageAccountingDocumentMessages'] +
  '/accountingDocumentEntries';
ROUTER_LINKS_FULL_PATH['accountingDocumentEntries'] =
  ROUTER_LINKS_FULL_PATH['collectionsManagement'] +
  '/accountingDocumentEntries';
ROUTER_LINKS_FULL_PATH['externalCollectionEngagementStatus'] =
  ROUTER_LINKS_FULL_PATH['collectionsManagement'] +
  '/externalCollectionEngagementStatus';
ROUTER_LINKS_FULL_PATH['externalCollectionsFeedbackMessages'] =
  ROUTER_LINKS_FULL_PATH['collectionsManagement'] +
  '/externalCollectionsFeedbackMessages';
ROUTER_LINKS_FULL_PATH['accountingDocumentCollectionFeedbackMessages'] =
  ROUTER_LINKS_FULL_PATH['collectionsManagement'] +
  '/accountingDocumentCollectionFeedbackMessages';

ROUTER_LINKS_FULL_PATH['others'] =
  ROUTER_LINKS_FULL_PATH['alevate'] + '/others';
ROUTER_LINKS_FULL_PATH['unknownAccounts'] =
  ROUTER_LINKS_FULL_PATH['others'] + '/unknown-accounts';
ROUTER_LINKS_FULL_PATH['certificateManagement'] =
  ROUTER_LINKS_FULL_PATH['others'] + '/certificate-management';
ROUTER_LINKS_FULL_PATH['manageCertificates'] =
  ROUTER_LINKS_FULL_PATH['certificateManagement'] + '/manage';
ROUTER_LINKS_FULL_PATH['btfMapping'] =
  ROUTER_LINKS_FULL_PATH['others'] + '/btfmapping';
ROUTER_LINKS_FULL_PATH['btfMappingManage'] =
  ROUTER_LINKS_FULL_PATH['btfMapping'] + '/manage';
ROUTER_LINKS_FULL_PATH['btfMappingUpdate'] =
  ROUTER_LINKS_FULL_PATH['btfMappingManage'] + '/%s';
ROUTER_LINKS_FULL_PATH['btfMappingDetails'] =
  ROUTER_LINKS_FULL_PATH['btfMappingUpdate'] + '/overview';
ROUTER_LINKS_FULL_PATH['audit'] = ROUTER_LINKS_FULL_PATH['others'] + '/audit';
ROUTER_LINKS_FULL_PATH['auditDetails'] =
  ROUTER_LINKS_FULL_PATH['audit'] + '/details';
ROUTER_LINKS_FULL_PATH['gdpr'] = ROUTER_LINKS_FULL_PATH['others'] + '/gdpr';

/** Alevate Rule Engine */

ROUTER_LINKS_FULL_PATH['areConfig'] =
  ROUTER_LINKS_FULL_PATH['ruleengine'] + '/are-config';

ROUTER_LINKS_FULL_PATH['areConfigList'] =
  ROUTER_LINKS_FULL_PATH['areConfig'] + '/are-config-list';

ROUTER_LINKS_FULL_PATH['ruleengineHome'] =
  ROUTER_LINKS_FULL_PATH['alevateruleengine'] + '/home';

ROUTER_LINKS_FULL_PATH['recipe'] =
  ROUTER_LINKS_FULL_PATH['ruleengine'] + '/recipe';

ROUTER_LINKS_FULL_PATH['MonitoringLog'] =
  ROUTER_LINKS_FULL_PATH['ruleengine'] + '/monitor-log';
