import { Component, Input, ViewEncapsulation } from '@angular/core';
import { WIDGETS } from '../../menu';

import { AutoUnsubscribe } from '@alevate/serrala-common/decorators';

@AutoUnsubscribe
@Component({
  selector: 'widget-overview',
  templateUrl: './overview.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class OverviewComponent{
  @Input() widgetType: string;
  WIDGETS = WIDGETS;

}
