<div id="add-task-widget" class="widget-wrapper" [ngClass]="widgetType == WIDGETS.ADD ? 'add-tasks-widget' : ''">
    <div class="widget-container">

        <div class="row ">
            <div class="col-12 widget-header">
                <ng-container *ngIf="widgetType == WIDGETS.ADD; else elseOverviewWidget">
                    <div class="widget-header--title">
                        <div class="d-flex flex-row">
                            <h5 class="widget-title main-title">{{ 'CORE.powerpanel.add' | translate }}</h5>

                        </div>
                    </div>
                    <div class="mb-3">

                    </div>
                </ng-container>
                <ng-template #elseOverviewWidget>
                    <div class="d-flex flex-row">
                        <div class="widget-title">{{ 'CORE.powerpanel.add' | translate }}</div>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
          {{ "CORE.powerpanel.WORK_IN_PROGRESS" | translate }}
        </div>
    </div>
</div>
