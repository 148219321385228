import { Component, OnInit } from '@angular/core';
import { ROUTER_LINKS_FULL_PATH } from '@app/core/constants';
import { Breadcrumb, BreadcrumbData } from '@alevate/serrala-breadcrumb';
import { EndpointDataCheckerService } from '../../core/services/endpoint-data-checker.service';
import { Router } from '@angular/router';
import { AutoUnsubscribe } from '@alevate/serrala-common/decorators';

@AutoUnsubscribe
@Component({
  selector: 'app-dashboard',
  template: `<router-outlet></router-outlet>`,
})
export class DashboardComponent extends Breadcrumb implements OnInit {
  constructor(
    breadcrumbData: BreadcrumbData,
    private endpointDataCheckerService: EndpointDataCheckerService,
    private router: Router
  ) {
    super(breadcrumbData);
  }

  ngOnInit() {
    sessionStorage.removeItem('breadCrumb');
    this.createBreadcrumb();
    this.redirectToEnter2Fa();
  }

  createBreadcrumb() {
    this.breadcrumbData.pushBreadcrumbData({
      key: 'dashboardSummary',
      label: 'Dashboard',
      isCustomLabel: true,
      routerLinkUrl: ROUTER_LINKS_FULL_PATH.dashboardSummary,
    });
  }

  /**
   * Checks on initialisation if 2fa has been set and active
   * otherwise will navigate to profile page to trigger the dialog
   * for 2fa setting
   */
  redirectToEnter2Fa() {
    this.endpointDataCheckerService
      .isAvailable('device2Fa')
      .then((result: boolean) => {
        if (result) {
          this.router.navigate([ROUTER_LINKS_FULL_PATH.profile], {
            queryParams: {
              mode: 'set2fa',
            },
          });
        }
      });
  }

  ngOnDestroy() {
    this.removeBreadcrumb();
  }
}
