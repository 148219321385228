import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTER_LINKS_FULL_PATH } from '@app/core/constants';

import { AutoUnsubscribe } from '@alevate/serrala-common/decorators';

@AutoUnsubscribe
@Component({
  selector: 'nav-empty-route',
  template: '',
})
export class EmptyRouteComponent implements OnInit {
  constructor(private _router: Router) {}

  ngOnInit() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    const hostname = window.location.hostname;
    const pathname = window.location.pathname;
    //redirect to dashboard if alevate in url not found
    if (hostname !== 'localhost' && !pathname.includes('/alevate')) {
      this._router.navigate([ROUTER_LINKS_FULL_PATH.dashboard]);
    }
  }
}
