import { ValidationService } from '@app/core/services/validation.service';
import { CoreService } from './services/core.service';
import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule } from '@angular/forms';
// import { RouterModule } from '@angular/router';

import { ThemesService } from './themes/themes.service';
import { TranslatorService } from '@alevate/serrala-common/services';

@NgModule({
  imports: [CommonModule, FormsModule],
  providers: [ThemesService, TranslatorService, CoreService, ValidationService],
  declarations: [],
  exports: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule has already been loaded. You should import Core modules only in the AppModule.'
      );
    }
  }
}
