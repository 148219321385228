import { Component, Input } from '@angular/core';
import { WIDGETS } from '../../menu';

import { AutoUnsubscribe } from '@alevate/serrala-common/decorators';

@AutoUnsubscribe
@Component({
  selector: 'widget-add-tasks',
  templateUrl: './add-tasks.component.html',
})
export class AddTasksComponent {
  @Input() widgetType: string;
  WIDGETS = WIDGETS;
}
