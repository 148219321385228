import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { OAuthService, OAuthErrorEvent } from 'angular-oauth2-oidc';
import { CoreService } from './../../../core/services/core.service';
import { Constants } from '@app/core/constants';

import { AutoUnsubscribe } from '@alevate/serrala-common/decorators';

@AutoUnsubscribe
@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss'],
})
export class SessionExpiredComponent implements OnInit, OnDestroy {
  private observer: MutationObserver;
  tokenRefreshFailed = false;

  constructor(
    private oauthService: OAuthService,
    private elementRef: ElementRef,
    private coreService: CoreService
  ) {}

  /**
   * Subscribes to OAuthService events and listens for token refresh errors.
   * When a token refresh error occurs, sets the tokenRefreshFailed flag to true
   * and logs the error to the console.
   */
  ngOnInit(): void {
    this.oauthService.events.subscribe({
      next: (event) => {
        if (
          event instanceof OAuthErrorEvent &&
          event.type === Constants.SESSION_EXPIRED.EVENT_TYPE
        ) {
          this.tokenRefreshFailed = true;
          console.error('Token refresh failed:', event);
        }
      },
    });
  }

  /**
   * Sets up a MutationObserver to observe changes to the component's native element.
   * When a child node is removed from the element, triggers the logoutUser method.
   * This is used to automatically log out a user when a specific element is removed from the DOM.
   */
  ngAfterViewInit(): void {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === Constants.SESSION_EXPIRED.MUTATION_TYPE &&
          mutation.removedNodes.length > 0
        ) {
          this.logoutUser();
        }
      });
    });
    observer.observe(this.elementRef.nativeElement, { childList: true });
  }

  logoutUser(): void {
    this.coreService.logout();
    this.tokenRefreshFailed = false;
  }

  /**
   * Disconnects the MutationObserver when the component is destroyed.
   * This is used to prevent memory leaks and stop observing changes to the DOM.
   */
  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
