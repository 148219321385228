import { Injectable } from '@angular/core';
import { MfaDevice } from '../../core/models/mfa-device';
import { BaseService } from '@alevate/serrala-common/services';
import { ApiConstant, Constants } from '@app/core/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EndpointDataCheckerService extends BaseService {
  constructor() {
    super();
  }

  async isAvailable(endpoint: string): Promise<boolean> {
    if (endpoint !== Constants.DATA_ENDPOINT.DEVICE_2FA) {
      return false;
    }
    try {
      const res: MfaDevice = await this.getDeviceListDetails().toPromise();
      if (!res.totpNeeded) {
        return false;
      }

      if (res.mfaDeviceList.length === 0) {
        return true;
      } else {
        return res.mfaDeviceList.some(
          (item) => item.type === 'TOTP' && item.status !== 'ACTIVE'
        );
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  /**
   * Returns the device list of the user.
   *
   * @returns Details for devices
   */
  getDeviceListDetails(): Observable<MfaDevice> {
    return this.httpClient.get<MfaDevice>(
      ApiConstant.constants.API_ENDPOINTS.settings.getUserDetailPage
    );
  }
}
