import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {Constants } from '@app/core/constants';
import { GetCdnToken200Response } from '@app/generated/tenant-service/model/getCdnToken200Response';
import { ApiConstant } from '@alevate/serrala-common/constants';

@Injectable({
    providedIn: 'root',
})
export class CdnService {
    constructor(private httpClient: HttpClient) { }

    /**
    * Service method to get a CDN Token.
    *
    * @returns {Observable<HttpResponse<string>>} An observable that emits an HTTP response when the token was fetched.
    */
    private getCdnLogoToken(): Observable<HttpResponse<GetCdnToken200Response>> {
        return this.httpClient.get<GetCdnToken200Response>(
            ApiConstant.constants.API_ENDPOINTS.tenantDetails.tenantLogoToken,
            { observe: 'response' }
        );
    }

    private shouldRefreshToken(): boolean {
        const tokenFromStorage = sessionStorage.getItem(Constants.CDN.TOKEN);
        if (tokenFromStorage === null) {
            return true;
        }
        var token: GetCdnToken200Response = JSON.parse(tokenFromStorage);
        return new Date(token.expiry) < new Date();
    }

    private refreshCdnToken(): void {
        this.getCdnLogoToken().subscribe(res => {
            if (res.body === null) {
                const emptyToken: GetCdnToken200Response = { cdnToken: '', expiry: new Date().toString() };
                sessionStorage.setItem(Constants.CDN.TOKEN, JSON.stringify(emptyToken));
            } else {
                sessionStorage.setItem(Constants.CDN.TOKEN, JSON.stringify(res.body));
            }
        });
    }

    getToken(): GetCdnToken200Response {
        if (this.shouldRefreshToken()) {
            this.refreshCdnToken();
        }
        const token = JSON.parse(sessionStorage.getItem(Constants.CDN.TOKEN));
        if (token === null) {
            const emptyToken: GetCdnToken200Response = { cdnToken: '', expiry: new Date().toString() };
            return emptyToken;
        } else {
            return token;
        }
    }
}