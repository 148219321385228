<div class="global-navbar">
  <div class="aside-container">
      <ejs-sidebar #sidebarMenuInstance id="sidebar-container" class="e-hidden" (mouseenter)="showSidebar()"
          (mouseleave)="hideSidebar()" (created)="onCreated()" [width]='width' [dockSize]='dockSize'
          [enableDock]='enableDock'>
          <div class="aside-inner">
              <div class="logo" (click)="pinNavbar()" tabindex="1" >
                  <span class="logo-img">
                      <img alt="logo" [src]="appDetails?.logo | assetUrl" />
                  </span>
                  <span class="company-name">{{ appDetails?.name }}</span>
              </div>
              <nav class="sidebar" id="sidebar" aria-label="sidebar">
                  <ul class="sidebar-nav">
                      <ng-container *ngFor='let item of menuItems; let index = index;'>
                          <ng-container *ngIf="!item?.isSubmenu; else elseIfSubmenu">
                              <li [routerLink]='item?.link' tabindex="{{ index + 1 }}" [class.active]="item?.isActive"
                                  (mouseenter)="clearSubmenu('submenu')">
                                  <span>
                                      <span class="icon">
                                          <em [ngClass]="item?.icon" ></em>
                                      </span>
                                      <span class="text-link">{{ item?.text | translate }}</span>
                                  </span>
                              </li>
                          </ng-container>
                          <ng-template #elseIfSubmenu>
                              <li tabindex="{{ index + 1 }}" (mouseenter)="showSubmenu('submenu', item?.text, item)"
                                  (mouseleave)="hideSubmenu('submenu')" [class.active]="item?.isActive">
                                  <span>
                                    <span class="icon" *ngIf="item?.icon != ''; else showImage">
                                      <em [ngClass]="item?.icon" ></em>
                                    </span>
                                    <ng-template #showImage>
                                      <span>
                                        <img alt="image" [src]="item?.image | assetUrl" width="auto" height="auto" />
                                      </span>
                                    </ng-template>
                                      <span class="text-link" [ngClass]="item?.icon == '' ? 'ml-4': ''">{{ item?.text | translate }}</span>
                                      <span class="icon-arrow-right">
                                          <em class="se-chevron-right"></em>
                                      </span>
                                  </span>
                              </li>
                          </ng-template>
                      </ng-container>
                  </ul>
              </nav>

              <ng-template let-menuLastTabIndex [ngTemplateOutletContext]="{ $implicit: (menuItems?.length + 2 + 1) }"
                  [ngTemplateOutlet]="t" #t>
                  <nav class="sidebar bottom-sidebar" aria-label="bottom-sidebar">
                      <ul class="sidebar-nav">
                          <ng-container *ngFor='let item of bottomMenuItems; let index = index;'>
                              <ng-container *ngIf="!item?.isSubmenu; else elseIfSubmenu">
                                  <ng-container *ngIf="!item?.isLogout; else elseLogoutBtn" >
                                      <li [routerLink]='item?.link' tabindex="{{ menuLastTabIndex + index }}" [class.active]="item?.isActive">
                                          <div class="d-flex flex-row">
                                              <ng-container *ngIf="item?.profilePic; else showIconContent">
                                                  <div class="profile-pic-container">
                                                      <div class="profile-pic">
                                                          <img [src]="item?.profilePic | assetUrl" [alt]="username" [title]="username"/>
                                                      </div>
                                                  </div>
                                                  <div class="profile-pic-text-link text-link">{{ item?.text | translate }}</div>
                                              </ng-container>
                                              <ng-template #showIconContent>
                                                  <div class="icon">
                                                      <em [ngClass]="item?.icon" ></em>
                                                  </div>
                                                  <div class="text-link">{{ item?.text | translate }}</div>
                                              </ng-template>


                                          </div>
                                      </li>
                                  </ng-container>
                                  <ng-template #elseLogoutBtn>
                                      <li [routerLink]='item?.link' (click)="logoutUser()" tabindex="{{ menuLastTabIndex + index }}" [class.active]="item?.isActive">
                                          <span>
                                              <span class="icon">
                                                  <em [ngClass]="item?.icon" ></em>
                                              </span>
                                              <span class="text-link">{{ item?.text | translate }}</span>
                                          </span>
                                      </li>
                                  </ng-template>

                              </ng-container>
                              <ng-template #elseIfSubmenu>
                                  <li tabindex="{{ menuLastTabIndex + index }}" (mouseenter)="showSubmenu('submenu', item?.text, item)"
                                  (mouseleave)="hideSubmenu('submenu')" [class.active]="item?.isActive">
                                      <span>
                                          <span class="icon">
                                              <em [ngClass]="item?.icon" ></em>
                                          </span>
                                          <span class="text-link">{{ item?.text | translate }}</span>
                                          <span class="icon-arrow-right">
                                              <em class="se-chevron-right" ></em>
                                          </span>
                                      </span>
                                  </li>
                              </ng-template>
                          </ng-container>
                      </ul>
                  </nav>
              </ng-template>
          </div>
      </ejs-sidebar>
  </div>

  <div id="submenu" class="sidenav" (mouseenter)="activeSubmenu(true, 'submenu')"
      (mouseleave)="activeSubmenu(false, 'submenu')">
    <div id="search-container">
      <h5 class="h5-b pl-3 mb-1">
          {{ submenuDetails?.text | translate }}
      </h5>
      <div class="pl-3 pr-3">
          <div class="search-bar">
              <input class="e-input e-field" type="text" [placeholder]="searchText" [(ngModel)]="filterVal" (input)="filterSubmenu()" />
          </div>
      </div>
    </div>

      <div class="submenu-list-container" id="submenu-container" [ngClass]="{'tenant-list-container':isTenantList}">
          <ul id="submenu-list-id" class="submenu-list pt-4" [ngStyle]="{'columns': !isTenantList ? this.menuColumns : 'unset'}" [ngClass]="{'tenant-list':isTenantList}">
              <ng-container *ngFor="let submenuItem of currentSubmenuItems">
                  <ng-container *ngIf="submenuItem?.submenu?.length">
                    <li id="{{submenuItem.key}}" class="item level-{{ submenuItem?.subModuleLevel }}">
                        <span>
                            <span class="text-link">{{ submenuItem?.text | translate }}</span>
                        </span>
                    </li>
                      <ng-container *ngFor="let subSubItem of submenuItem?.submenu">
                          <li *ngIf="subSubItem?.link != ''; else clickTemplate;" id="{{subSubItem?.key}}" (click)="hideSidebarAndSubMenu()" class="item level-{{ subSubItem?.subModuleLevel }}" [routerLink]='subSubItem?.link' routerLinkActive='active'>
                              <span>
                                  <span class="text-link">{{ subSubItem?.text | translate }}</span>
                              </span>
                          </li>
                          <ng-template #clickTemplate>
                            <li id="{{subSubItem?.key}}"  class="item level-{{ subSubItem?.subModuleLevel }}" (click)="switchTenant(subSubItem)" [ngClass]="subSubItem?.isActive ? 'active': ''">
                              <span>
                                  <span class="text-link">{{ subSubItem?.text | translate }}</span>
                              </span>
                            </li>                            
                            <li *ngIf="subSubItem?.submenu?.length > 0">
                                <ul class="submenu-list nested-tenant-item">                                      
                                      <ng-container *ngFor="let item of subSubItem?.submenu, let i = index">
                                        <li id="{{item?.key}}" class="item level-{{ subSubItem?.subModuleLevel }} logo" (click)="switchTenant(item)" [ngClass]="item?.isActive ? 'active': ''">
                                            <div class="d-flex flex-row align-items-center ml-n1">
                                                <img alt="" [ngClass]="tenantLogoError[i] ? 'default-logo' : 'tenant-logo'" (error)="handleLogoError(i)" [src]="getTenantsLogo(item?.key, i, subSubItem.submenu.length)"/>
                                                <span class="text-link">{{ item?.text | translate }}</span>
                                            </div>
                                        </li>
                                    </ng-container>
                                </ul>
                            </li>
                          </ng-template>
                      </ng-container>
                  </ng-container>

                  <div class="mb-4"></div>
              </ng-container>
          </ul>
      </div>
  </div>
</div>
<serrala-toaster #toast></serrala-toaster>
