/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const GRID_ACTION_REQUEST_TYPES = {
  beginEdit: 'beginEdit',
  add: 'add',
  save: 'save',
  update: 'update',
  columnstate: 'columnstate',
  filtering: 'filtering',
  sorting: 'sorting',
};

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const GRID_SELECTION_MODES = {
  row: 'Row',
  cell: 'Cell',
  both: 'Both',
};

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const GRID_SELECTION_TYPES = {
  single: 'Single',
  multiple: 'Multiple',
};

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const GRID_SORTING_DIRECTION = {
  ascending: 'ASCENDING',
  descending: 'DESCENDING',
};

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const GRID_SETTINGS_MODES = {
  normal: 'Normal',
  batch: 'Batch',
};

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const GRID_FILTER_SETTINGS_MODES = {
  immediate: 'Immediate',
  onEnter: 'OnEnter',
};

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const COLUMN_MENU_ITEMS = [
  'AutoFitAll',
  'AutoFit',
  'SortAscending',
  'SortDescending',
];

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const COLUMN_MENU = [
  'AutoFit',
  'SortAscending',
  'SortDescending',
  { text: 'Clear Sorting', id: 'gridclearsorting' },
];

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const COLUMN_MENU_SORTING = [
  'SortAscending',
  'SortDescending',
  { text: 'Clear Sorting', id: 'gridclearsorting' },
];

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const GRID_CURRENT_ACTIVE_TOOL = {
  groupBy: 'groupBy',
  bulkActions: 'bulkActions',
  search: 'search',
  addConfigurationPack:'addConfigurationPack'
};

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const GRID_FILE_EXPORT_TYPE = {
  exportPdf: 'export_pdf',
  exportExcel: 'export_excel',
};
