<div id="overview-widget" class="widget-wrapper" [ngClass]="widgetType == WIDGETS.OVERVIEW ? 'overview-widget' : ''">
    <div class="widget-container mb-0">
        <div class="row ">
            <div class="col-12 widget-header">
                <div class="d-flex flex-row">
                    <h5 class="widget-title main-title">{{ 'CORE.powerpanel.overview' | translate }}</h5>
                </div>
                <div>
                </div>
            </div>
        </div>
    </div>
    <div class="row d-flex justify-content-center">
      {{ "CORE.powerpanel.WORK_IN_PROGRESS" | translate }}
    </div>
    <div class="row">
        <div class="col-12">
            <widget-notifications [widgetType]="'overview'" class="d-none"></widget-notifications>
        </div>
    </div>
</div>
