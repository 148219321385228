/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export class ErrorConstants {
  static readonly ERRORS = {
    BAD_REQUEST: 400,
    INVALID_REQUEST: 412,
    UNAUTHORIZE: 401,
    FORBIDDEN: 403,
    BAD_GATEWAY: 502,
    INTERNAL_SERVER_ERROR: 500,
    PAGE_NOT_FOUND : 404
  };
}
