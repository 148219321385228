import { AssetUrlPipe } from '../pipes/assetUrl.pipe';
import {
  FieldErrorDisplayModule,
  FieldDataDisplayModule,
  CustomFormDialogModule,
  ConfirmModalModule,
  BulkActionModalModule,
  ErrorStatusCardModule,
  ManualImportModule,
  MultiDatePickerModule,
  CommonFileImportModule,
} from '@alevate/serrala-common/components';
import {
  SyncfusionModules,
  SerralaCommonModule,
} from '@alevate/serrala-common';
import { PipesModule } from '@alevate/serrala-common/pipes';
import {
  ResolversModule,
  ApprovalWorflowAuthResolver,
} from '@alevate/serrala-common/resolvers';
import { DirectivesModule } from '@alevate/serrala-common/directives';
import {
  ManualImportSharedService,
  CommonFileImportService,
  RedirectService,
} from '@alevate/serrala-common/services';

import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SerralaToasterModule } from '@alevate/serrala-toaster';
import { BreadcrumbModule } from '@alevate/serrala-breadcrumb';

@NgModule({
  declarations: [AssetUrlPipe],
  providers: [
    ManualImportSharedService,
    CommonFileImportService,
    AssetUrlPipe,
    ApprovalWorflowAuthResolver,
    TranslateService,
    RedirectService,
    PipesModule,
  ],

  imports: [
    CommonModule,
    BreadcrumbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    SyncfusionModules,
    NgxSkeletonLoaderModule,
    SerralaToasterModule,
    SerralaCommonModule,
    ConfirmModalModule,
    BulkActionModalModule,
    ErrorStatusCardModule,
    ManualImportModule,
    MultiDatePickerModule,
    CommonFileImportModule,
    ResolversModule,
    FieldErrorDisplayModule,
    FieldDataDisplayModule,
    DirectivesModule,
    CustomFormDialogModule,
    PipesModule,
  ],
  exports: [
    CommonModule,
    BreadcrumbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    SyncfusionModules,
    NgxSkeletonLoaderModule,
    SerralaToasterModule,
    SerralaCommonModule,
    ConfirmModalModule,
    BulkActionModalModule,
    ErrorStatusCardModule,
    ManualImportModule,
    MultiDatePickerModule,
    CommonFileImportModule,
    ResolversModule,
    FieldErrorDisplayModule,
    FieldDataDisplayModule,
    DirectivesModule,
    CustomFormDialogModule,
    PipesModule,
    AssetUrlPipe,
  ],

  //exports: [
  //  // AuthLibModule,
  //  CommonModule,
  //  // CommonLibModule,
  //  FormsModule,
  //  ReactiveFormsModule,
  //  TranslateModule,
  //  SerralaToasterModule,
  //  FieldErrorDisplayComponent,
  //  ToastMessageComponent,
  //  SortFieldBySequencePipe,
  //  ToNumberPipe,
  //  ConfirmModalComponent,
  //  NgxSkeletonLoaderModule,
  //  BulkActionModalComponent,
  //  FieldDataDisplayComponent,
  //  SearchPipe,
  //  AmountFormatPipe,
  //  // ChangeExecutionDateComponent,
  //  TrimTextDirective,
  //  ErrorStatusCardComponent,
  //  CustomFormDialogComponent,
  //  AssetUrlPipe,
  //  ManualImportComponent,
  //  TruncatePipe,
  //  SpecialTextPipe,
  //  MultiDatePickerComponent,
  //  IbanPipe,
  //  IbanMaskDirective,
  //  CommonFileImportComponent,
  //],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
