import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TriggerService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  private subject = new BehaviorSubject<any>({});

  setEvent(event: any) {
    this.subject.next({ event });
  }

  clearEvent() {
    this.subject.next({ event: {} });
  }

  getEvent(): Observable<any> {
    return this.subject.asObservable();
  }
}
