/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { StackedChart } from "@alevate/serrala-charts";
import { SerraConstants } from "@app/layout/off-sidebar/widgets/serra-constants";

export class SerraUtils {
  
  static balanceMovement: StackedChart;

  /**
   * Returns chart type for specified intent
   */
  static getChartType(intentName: string): string {
    switch (intentName) {
      case SerraConstants.INTENT_NAME.TOP_ADF_FILES: {
        return SerraConstants.CHART_TYPE.BAR;
      }
      case SerraConstants.INTENT_NAME.CASH_POSITION: {
        return SerraConstants.CHART_TYPE.LINE;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Returns chart config for specified intent
   */
  static getChartConfig(intentName: string): any {
    switch (intentName) {
      case SerraConstants.INTENT_NAME.TOP_ADF_FILES: {
        return SerraConstants.CHART_CONFIG.TOP_ADF_FILES;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Returns formatted chart data for specified intent
   */
  static getChartData(intentName: string, chartData: any): any {
    switch (intentName) {
      case SerraConstants.INTENT_NAME.TOP_ADF_FILES: {
        const formattedChartData = [];
        for (const data of chartData) {
          const dataObj = {
            x: Number(data.numberOfRecords),
            y: data.fileId,
          };
          formattedChartData.unshift(dataObj);
        }
        return formattedChartData;
      }
      case SerraConstants.INTENT_NAME.CASH_POSITION: {
        // get line chart config
        this.balanceMovement = SerraConstants.CHART_CONFIG.BALANCE_MOVEMENT;
        // set chart data to datasource
        this.balanceMovement.chartApperance[0].dataSource = chartData;
        return this.balanceMovement;
      }
      default: {
        break;
      }
    }
  }
}
