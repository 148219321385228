/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { StackedChart } from '@alevate/serrala-charts';
import { Component, Input, OnChanges } from '@angular/core';
import { TriggerService } from '@app/core/services/trigger.service';
import { Utils } from '@app/shared/utils/utils';
import { WIDGETCONSTANTS } from '../../constants/widget-constants';
import { ChatService } from '../services/chat.service';
import { SerraConstants } from '@app/layout/off-sidebar/widgets/serra-constants';

import { AutoUnsubscribe } from '@alevate/serrala-common/decorators';

@AutoUnsubscribe
@Component({
  selector: 'app-chat-panel',
  templateUrl: './chat-panel.component.html',
  styleUrls: ['./chat-panel.component.scss'],
})
export class ChatPanelComponent implements OnChanges {
  @Input() messages?;
  @Input() botResponse;
  sentbyBot = WIDGETCONSTANTS.DIGITALASSITANT.sentByChatBot;
  sentbyUser = WIDGETCONSTANTS.DIGITALASSITANT.sentByUser;
  scriptedIntent = '';
  scriptedResponse = [];
  scriptedFlow = false;
  balanceMovement: StackedChart;
  dataSource = {
    balanceDetails: [
      {
        graphPeriod: '2022-09-20',
        period: '09-20',
        balance: 15667.58,
      },
      {
        graphPeriod: '2022-09-21',
        period: '09-21',
        balance: 7072.21,
      },
      {
        graphPeriod: '2022-09-22',
        period: '09-22',
        balance: 19175.69,
      },
      {
        graphPeriod: '2022-09-23',
        period: '09-23',
        balance: 24375.69,
      },
      {
        graphPeriod: '2022-09-24',
        period: '09-24',
        balance: -54175.69,
      },
    ],
    totalBalance: 2984.15,
    widgetName: 'BALANCE_MOVEMENT',
  };
  intentName: string;
  chartData = [];
  showChartDemonstration = false;

  constructor(
    private chatService: ChatService,
    private triggerService: TriggerService
  ) {
    this.balanceMovement = {
      title: 'Balance Movement',
      seriesCount: 1,
      chartApperance: [
        {
          dataSource: [],
          type: 'Line',
          xName: 'period',
          yName: 'balance',
          name: '',
          width: 2,
          marker: {
            visible: true,
          },
        },
      ],
      chartProps: {
        width: '100%',
        height: '50%',
        margin: {
          left: 0,
          right: 50,
          top: 0,
          bottom: 0,
        },
        primaryXAxis: {
          valueType: 'Category',
          labelIntersectAction: 'Rotate45',
          majorGridLines: { color: '#f1f1f1', width: 1 },
          minorGridLines: { width: 1 },
          majorTickLines: { width: 1 },
          minorTickLines: { width: 1 },
        },
        primaryYAxis: {
          labelFormat: 'N2',
          titleStyle: {
            fontWeight: '600',
            fontFamily: 'NotoSans',
            color: '#3c4049',
          },
          rangePadding: 'Additional',
          edgeLabelPlacement: 'Shift',
          majorGridLines: { color: '#f1f1f1', width: 1 },
          minorGridLines: { width: 1 },
          majorTickLines: { width: 1 },
          minorTickLines: { width: 1 },
        },
        tooltip: {
          enable: true,
        },
        palettes: ['#92E2A0'],
      },
    };
  }
  ngOnChanges(): void {
    this.triggerService.getEvent().subscribe({
      next: (res) => {
        if (res?.event?.messageSent) {
          this.balanceMovement.chartApperance[0].dataSource = [];
          this.showChartDemonstration = false;
        }
      },
    });
    if (this.botResponse) {
      this.updateConversation(this.botResponse);
      this.isScriptedFlow();
    }
    this.chatService.scrollToBottom();
  }

  /**
   * Updates conversation;
   *
   * @param response;
   */
  updateConversation(response: any): void {
    this.scriptedResponse = [];
    this.chatService.updateScriptedFlow(false);
    if (
      response &&
      response.serra_response &&
      response.serra_response.is_scripted
    ) {
      if (Array.isArray(response.serra_response.concatenate_response)) {
        this.chatService.updateScriptedFlow(true);
        this.scriptedResponse = response.serra_response.concatenate_response;
      } else {
        switch (response?.serra_response?.intent_name) {
          case SerraConstants.INTENT_NAME.TOP_ADF_FILES: {
            this.intentName = response?.serra_response?.intent_name;
            this.chartData =
              response.serra_response.concatenate_response?.widget_data?.fileRecords;
            this.showChartDemonstration = true;
            break;
          }
          // balance movement chart for cash management
          case SerraConstants.INTENT_NAME.CASH_POSITION: {
            this.intentName = response?.serra_response?.intent_name;
            this.chartData =
              response.serra_response.concatenate_response?.widget_data;
            this.showChartDemonstration = true;
            break;
          }
          default: {
            break;
          }
        }
      }
    }
    if (
      typeof response?.serra_response !== 'object' &&
      response?.serra_response?.includes('value balance')
    ) {
      this.balanceMovement.chartApperance[0].dataSource =
        this.dataSource.balanceDetails;
    }
  }
  /**
   * Determines whether scripted flow is;
   */
  isScriptedFlow(): void {
    this.scriptedFlow = !!this.botResponse?.serra_response?.is_scripted;
  }

  /**
   * Formats chart balance values in the form of thousand and millions
   *
   * @param args
   */
  formatChartBalanceValues(args: any): void {
    if (args) {
      Utils.formatChartValues(args);
    }
  }
}
