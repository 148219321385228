import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { of, catchError, Observable } from 'rxjs';
import { ApiConstant } from '../constants/api-constant';
import { PasswordData } from '../models/password-data';
import { TenantDetails } from '@app/generated/tenant-service/model/models';
import { Tenant } from '../models/tenant';

import { AuthenticationService } from '@alevate/serrala-auth-lib';
import { OAuthStorage } from 'angular-oauth2-oidc';

@Injectable()
export class CoreService {
  private tenantUrl = ApiConstant.constants.API_ENDPOINTS.getAllTenantUrl;
  private oauthService: AuthenticationService;
  private readonly changePasswordUrl =
    ApiConstant.constants.API_ENDPOINTS.settings.changePasswordPing;
  private readonly switchTenantUrl =
    ApiConstant.constants.API_ENDPOINTS.settings.switchTenant;
  constructor(
    private httpClient: HttpClient,
    private injector: Injector,
    private authStorage: OAuthStorage
  ) {}

  getAuthService(): AuthenticationService {
    if (!this.oauthService) {
      this.oauthService = this.injector.get(AuthenticationService);
    }
    return this.oauthService;
  }

  /**
   *
   * Auth logout service was not working so have to implement it
   *
   * @memberof CoreService
   */
  logout(): void {
    if (this.authStorage.getItem('access_token')) {
      this.getAuthService().logout();
      this.frontEndLogout();
    }
  }

  getAllTenants(): Observable<Tenant[]> {
    return this.httpClient
      .post<Tenant[]>(`${this.tenantUrl}`, { observe: 'body' })
      .pipe(
        catchError((error) => {
          if (error.status !== 404) {
            throw error;
          }

          return of([] as Tenant[]);
        })
      );
  }

  /**
   * Returns the list of Tenants(Operators and Customers both) to which
   * the current user is assigned.
   *
   * @returns Assigned Tenants
   */
  getAssignedTenants(): Observable<TenantDetails[]> {
    return this.httpClient.get<TenantDetails[]>(
      ApiConstant.constants.API_ENDPOINTS.settings.assignedTenants
    );
  }

  frontEndLogout(): void {
    sessionStorage.removeItem('breadCrumb');
    sessionStorage.removeItem('permittedMenuItems');
  }

  changePassword(
    passwordChangeRequest: PasswordData
  ): Observable<HttpResponse<void>> {
    return this.httpClient
      .post<void>(this.changePasswordUrl, passwordChangeRequest, {
        observe: 'response',
      })
      .pipe(
        catchError(
          (error) => {
            throw error;
          }
          //this.errorHandler.handleError<HttpResponse<void>>(error)
        )
      );
  }

  /**
   *
   * API Call to Switch Tenant
   *
   * @param {string} userId
   * @param {number} tenantId
   * @returns {*}  {Observable<HttpResponse<void>>}
   * @memberof CoreService
   */
  switchTenant(
    userId: string,
    tenantId: number
  ): Observable<HttpResponse<void>> {
    return this.httpClient
      .post<void>(
        `${this.switchTenantUrl}`,
        { id: userId, tenant: tenantId },
        { observe: 'response' }
      )
      .pipe(
        catchError(
          (error) => {
            console.log(error);
            throw error;
          }
          // this.errorHandler.handleError<HttpResponse<void>>(error)
        )
      );
  }
}
