// import { AuthGuardService } from '@alevate/serrala-auth-lib';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ThemesService } from '@app/core/themes/themes.service';
import { TranslatorService } from '@alevate/serrala-common/services';
import { BreadcrumbData, BreadcrumbModule } from '@alevate/serrala-breadcrumb';
import { SharedModule } from '@app/shared/modules/shared.module';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { SidebarModule } from '@syncfusion/ej2-angular-navigations';
// import { commonFeaturesRoutes } from '../routes/common-features.routes';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout.component';
import { OffSidebarModule } from './off-sidebar/off-sidebar.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SessionExpiredComponent } from '@app/features/others/session-expired/session-expired.component';

const routes: Routes = [
  // {
  //     path: '',
  //     component: LayoutComponent,
  //     canActivate: [AuthGuardService],
  //     // children: featuresRoutes,
  // },
];

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    SessionExpiredComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SidebarModule,
    TextBoxModule,
    RouterModule.forChild(routes),
    OffSidebarModule,
    BreadcrumbModule,
  ],
  exports: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    OffSidebarModule,
    SessionExpiredComponent,
  ],
  providers: [TranslatorService, ThemesService, BreadcrumbData],
})
export class LayoutModule {}
