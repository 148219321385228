import { ErrorService } from "./error.service";
import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastService } from "@alevate/serrala-common/services";
import { ApiConstant, HTTP_RESPONSE_CODES } from "../constants";
import { ToastUtility } from "@syncfusion/ej2-notifications";
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  toastObj;
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse) {
    const errorService = this.injector.get(ErrorService);
    const toaster = this.injector.get(ToastService);
    let message;
    if (error instanceof HttpErrorResponse) {
      // Server error
      message = errorService.getServerErrorMessage(error);
      if (message.error && message.status && message.message) {
        toaster.showToast(
          `${message.error} : ${message.status} ${message.message}`,

          true
        );
      } else {
        // This error condition can be removed once notification resolves
        // adding temporary condition to avoid error toasters for demos
        if (
          !error.url.includes(
            ApiConstant.constants.API_ENDPOINTS.notifications
              .getAllNotifications
          )
        ) {
          toaster.showToast(`${message}`, true);
        }
      }
      console.error(message);
    } else {
      console.log(error);
    }
    // Always log errors
  }
  toastClose(): void {
    this.toastObj.hide();
  }
}
