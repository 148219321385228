import { BAR_HORIZONTAL_COLOR_CODES } from "@app/core/constants";
import { COLOR_HEX } from "@app/core/constants/color-costant";

export class SerraConstants {
  static CHART_TYPE = {
    BAR: "BAR",
    LINE: 'line'
  };

  static CHART_CONFIG = {
    TOP_ADF_FILES: {
      chartProps: {
        theme: "Material",
        palettes: [BAR_HORIZONTAL_COLOR_CODES.count],
        height: "50%",
        width: "100%",
        margin: { left: 0, right: 0, top: 25, bottom: 0 },
        primaryXAxis: {
          valueType: "Category",
          interval: 1,
          majorGridLines: { width: 0 },
          majorTickLines: { width: 0 },
        },
        primaryYAxis: {
          lineStyle: { width: 0 },
          majorTickLines: { width: 0 },
          labelFormat: "{value}",
          edgeLabelPlacement: "Shift",
          // interval: 100000,
          rangePadding: "Round",
        },
        chartArea: {
          border: {
            width: 0,
          },
        },
        tooltip: {
          enable: true,
        },
        legendSettings: {
          visible: false,
        },
      },
      seriesCount: 1,
      chartApperance: {
        showLoader: false,
        type: "Bar",
        xName: "y",
        yName: "x",
        name: "",
        marker: {
          dataLabel: {
            visible: true,
            position: "Top",
            font: {
              fontWeight: "600",
              color: COLOR_HEX.black,
            },
          },
        },
      },
    },
    BALANCE_MOVEMENT: {
      title: "Balance Movement",
      seriesCount: 1,
      chartApperance: [
        {
          dataSource: [],
          type: "Line",
          xName: "period",
          yName: "balance",
          name: "",
          width: 2,
          marker: {
            visible: true,
          },
        },
      ],
      chartProps: {
        width: "100%",
        height: "55%",
        margin: { 
          left: 0,
          right: 0,
          top: 5,
          bottom: 0
        },
        primaryXAxis: {
          valueType: "Category",
          labelIntersectAction: "Rotate45",
          majorGridLines: { color: "#f1f1f1", width: 1 },
          minorGridLines: { width: 1 },
          majorTickLines: { width: 1 },
          minorTickLines: { width: 1 },
        },
        primaryYAxis: {
          labelFormat: "N2",
          titleStyle: {
            fontWeight: "600",
            fontFamily: "NotoSans",
            color: "#3c4049",
          },
          rangePadding: "Additional",
          edgeLabelPlacement: "Shift",
          majorGridLines: { color: "#f1f1f1", width: 1 },
          minorGridLines: { width: 1 },
          majorTickLines: { width: 1 },
          minorTickLines: { width: 1 },
        },
        tooltip: {
          enable: true,
        },
        palettes: ["#92E2A0"],
      },
    },
  };

  static INTENT_NAME = {
    TOP_ADF_FILES: "collections.top.adf.files",
    COUNTRY_CODES: "Country_code",
    CASH_POSITION: 'cm.cash.position'
  };
}
