import { Component, Input } from '@angular/core';
import { WIDGETS } from '../../menu';

import { AutoUnsubscribe } from '@alevate/serrala-common/decorators';

@AutoUnsubscribe
@Component({
  selector: 'widget-help',
  templateUrl: './help.component.html',
})
export class HelpComponent {
  @Input() widgetType: string;
  WIDGETS = WIDGETS;

}
