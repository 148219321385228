<div id="my-tasks-widget" class="widget-wrapper" [ngClass]="widgetType == WIDGETS.MY_TASKS ? 'my-tasks-widget' : ''">
    <div class="widget-container">
        <div class="row ">
            <div class="col-12 widget-header">
                <ng-container *ngIf="widgetType == WIDGETS.MY_TASKS; else elseOverviewWidget">
                    <div class="widget-header--title">
                        <div class="d-flex flex-row">
                            <h5 class="widget-title main-title">{{ 'CORE.powerpanel.myTasks' | translate }} <span class="notifications-unseen"></span></h5>
                            <div class="widget-settings d-flex flex-row ml-auto">
                                <div class="mr-2">
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                    </div>
                </ng-container>
                <ng-template #elseOverviewWidget>
                    <div class="d-flex flex-row">
                        <div class="widget-title">{{ 'CORE.powerpanel.myTasks' | translate }}</div>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
          {{ "CORE.powerpanel.WORK_IN_PROGRESS" | translate }}
        </div>
    </div>
</div>
