import {
  MENU_CONFIG,
  BOTTOM_MENU_CONFIG,
  BOTTOM_MENU_WHICH_REQUIRE_PERMISSION,
} from './menu.config';
export const menu = MENU_CONFIG;

export const bottomMenu = BOTTOM_MENU_CONFIG;

export const bottomMenuItemsWhichNeedPermssions =
  BOTTOM_MENU_WHICH_REQUIRE_PERMISSION;
