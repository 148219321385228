import { BaseService } from '@alevate/serrala-common/services';
import { Injectable, Injector, OnDestroy } from '@angular/core';

@Injectable()
export class DependencyInjector implements OnDestroy {
  static injector: Injector;
  static inj: Injector;
  static setInjector(injector: Injector): void {
    DependencyInjector.injector = injector;
    //DependencyInjector.inj = injector;
    BaseService.setDependencyInj(injector);
  }

  static getInjector(): Injector {
    return DependencyInjector.injector;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    // localStorage.removeItem('injector');
  }
}
