/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Input } from '@angular/core';
import { TriggerService } from '@app/core/services/trigger.service';
import { ChatService } from '../services/chat.service';
import { ROUTER_LINKS_FULL_PATH } from '@app/core/constants';
import { Router } from '@angular/router';
import { Common } from '@alevate/serrala-common';

import { AutoUnsubscribe } from '@alevate/serrala-common/decorators';

@AutoUnsubscribe
@Component({
  selector: 'app-payment-cards',
  templateUrl: './payment-cards.component.html',
  styleUrls: ['./payment-cards.component.scss'],
})
export class PaymentCardsComponent implements OnInit {
  @Input() scriptedData: any;
  @Input() indexText?: any;
  @Input() userAnomolyText?: any;
  dataObjKeys = Object.keys;
  userSelection = '';
  actionResponse = '';
  hasActionTaken = false;
  constructor(
    private triggerService: TriggerService,
    private chatService: ChatService,
    private router: Router
  ) {}
  // private paymentItemsObservableService: PaymentItemsObservableService

  ngOnInit(): void {
    this.triggerService.clearEvent();
  }
  /**
   * Triggers payment cards component;
   *
   * @param action;
   */
  trigger(action: any): void {
    const prefixUrl = '/backend/paymentinput';
    this.userSelection = action.buttonText;
    this.hasActionTaken = true;
    if (action.httpMethod === 'PUT') {
      const url = prefixUrl + action.uri;
      this.chatService.updateAnamoly(url).subscribe({
        next: (res) => {
          if (res) {
            this.actionResponse = action.onClickMessage;
            this.triggerService.setEvent({ updateScriptedFlow: true });
          }
        },
      });
    } else {
      const url = prefixUrl + action.uri;
      this.chatService.removeAnamoly(url).subscribe({
        next: (res) => {
          if (res) {
            this.actionResponse = action.onClickMessage;
            this.triggerService.setEvent({ updateScriptedFlow: true });
          }
        },
      });
    }
    this.chatService.scrollToBottom();
  }

  /**
   * Navigates to item page;
   *
   * @param currfile;
   */
  navigateToItemPage(currfile: any): void {
    const redirectUrl = Common.sprintf(
      ROUTER_LINKS_FULL_PATH.paymentItemDetails,
      [currfile?.paymentItem?.fileId]
    );
    sessionStorage.setItem(
      'metaFieldsDTO',
      JSON.stringify(currfile?.paymentItem)
    );
    this.router.navigate([redirectUrl], {
      queryParams: { mode: 'view', fromSerra: true },
    });
  }
}
