<ng-container>
  <!-- top navbar-->
  <app-header class="topnavbar-wrapper"></app-header>
  <!-- offsidebar-->
  <app-sidebar *ngIf="showSidebar" class="leftsidebar" (toggledSidebar)="toggledSidebar($event)"></app-sidebar>
  <app-off-sidebar *ngIf="showSidebar" class="rightsidebar" (toggledPowerPanel)="toggledPowerPanel($event)">
  </app-off-sidebar>
  <!-- Main section-->
  <section class="section-container" id="sectionContainer">
    <!-- Page content-->
    <div class="content-wrapper pb-0">
      <ng-container *ngIf="isTenantInPreparation()">
        <div class="d-flex justify-content-center align-items-center pl-2 pr-2 tenant-preparation-info">
          <em class="se-info-circle pr-1"></em><div>{{ 'SHARED.WARNING_MESSAGES.inPreparation' | translate }}</div>
        </div>
      </ng-container>
      <serrala-breadcrumb [breadCrumbDetails]="breadCrumbDetails" *ngIf="!hideBreadcrumb"></serrala-breadcrumb>
      <router-outlet></router-outlet>
    </div>
  </section>
  <!-- session expired to login page -->
  <app-session-expired></app-session-expired>
  <!-- Page footer-->
</ng-container>