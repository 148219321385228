/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Input } from "@angular/core";
import { SerraUtils } from "@app/layout/off-sidebar/widgets/serra-utils";
import { SerraConstants } from "@app/layout/off-sidebar/widgets/serra-constants";
import { Utils } from "@app/shared/utils/utils";

import { AutoUnsubscribe } from '@alevate/serrala-common/decorators';

@AutoUnsubscribe
@Component({
  selector: "serra-chart-container",
  templateUrl: "./serra-chart-container.component.html",
})
export class SerraChartContainerComponent {
  @Input() intentName?: string;
  @Input() chartData?: any;
  SerraConstants = SerraConstants;

  /**
   * Gets chart type for specified intent
   */
  getChartType(): string {
    return SerraUtils.getChartType(this.intentName);
  }

  /**
   * Gets chart config for specified intent
   */
  getChartConfig(): any {
    return SerraUtils.getChartConfig(this.intentName);
  }

  /**
   * Gets chart data for specified intent
   */
  getChartData(): any {
    return SerraUtils.getChartData(this.intentName, this.chartData);
  }

  /**
   * Formats chart balance values in the form of thousand and millions
   *
   * @param args
   */
   formatChartBalanceValues(args: any): void {
    if (args) {
      Utils.formatChartValues(args);
    }
  }
}
