import { Component } from '@angular/core';

import { AutoUnsubscribe } from '@alevate/serrala-common/decorators';

@AutoUnsubscribe
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent  {

}
