import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { environment } from '@environments/environment';
import { RedirectService } from '@alevate/serrala-common/services';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { AppInfo, AuthGuardService } from '@alevate/serrala-auth-lib';

const initUrl = environment.baseRoute;

const routes: Routes = [
  {
    // path: 'alevate', //DI
    path: environment.baseUrl,
    canActivate: [RedirectService],
    component: DashboardComponent,
    pathMatch: 'full',
  },
  {
    path: initUrl + 'home',
    //removed authorization to display side bar when user doesnt have permission for dashboard.
    data: new AppInfo({
      APP_NAME: 'dashboard',
      PRODUCT: 'product-independent',
    }),
    canActivate: [AuthGuardService],
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: initUrl + 'dashboard',
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: initUrl + 'masterdata',
    loadChildren: () =>
      import('./features/master-data/master-data.module').then(
        (module) => module.MasterDataModule
      ),
  },
  {
    path: initUrl + 'settings',
    loadChildren: () =>
      import('./features/settings/settings.module').then(
        (module) => module.SettingsModule
      ),
  },
  {
    path: initUrl + 'importLogging',
    loadChildren: () =>
      import('./features/import-logging/import-logging.module').then(
        (module) => module.ImportLoggingModule
      ),
  },
  {
    path: initUrl + 'externalCollectionsSetup',
    loadChildren: () =>
      import(
        './features/external-collections-setup/external-collections-setup.module'
      ).then((module) => module.ExternalCollectionsSetupModule),
  },
  {
    path: initUrl + 'others',
    loadChildren: () =>
      import('./features/others/others.module').then((m) => m.OthersModule),
  },
  {
    path: initUrl + 'registrationConfirm',
    loadChildren: () =>
      import('./features/registration/complete-registration.module').then(
        (module) => module.CompleteRegistrationModule
      ),
  },
  {
    path: initUrl + 'invitationConfirm',
    loadChildren: () =>
      import('./features/invitation/confirm-invitation.module').then(
        (module) => module.ConfirmInvitationModule
      ),
  },
  {
    path: initUrl + 'error',
    loadChildren: () =>
      import('./features/others/error/error.module').then((m) => m.ErrorModule),
  },
  { path: '**', component: EmptyRouteComponent },
  {
    path: '***',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes,
       {
      paramsInheritanceStrategy: 'always',
      initialNavigation: 'enabledBlocking',
      // enableTracing: true,
    },
    ),
  ],
  exports: [RouterModule],
})
export class AlevateNavigationRoutingModule {}
