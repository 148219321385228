export const POWER_PANEL = {
    collapsedWidth: 50,
    expadedWidth: 410,
    closeOnDocumentClick: true
}

export const GLOBAL_NAVBAR = {
    collapsedWidth: 50,
    expadedWidth: 200,
    submenuExpadedWidth: 220,
    closeOnDocumentClick: false
}